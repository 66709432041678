import {
  Link,
  Segment,
  theme,
  Subheading,
  Block,
  Text,
} from "@blueorigin/blue-branding-kit";
import React from "react";
import { Routes } from "../../../routes";
import styled from "styled-components";
import { Disposition, DispositionStatus } from "../../../models/generated";
import { Card, CardContent } from "@material-ui/core";
import { JulesContent } from "../../layout/jules-content";
import { Spacer } from "../../layout/spacer";
import { SignOff } from "../../layout/sign-off";
import _ from "lodash";
import { useLookup } from "../../../hooks/use-lookup";
import { Deviations } from "./nonconformance-deviations";

const Flex = styled.div`
  ${Spacer} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const H3 = styled(Subheading)`
  text-transform: initial;
  letter-spacing: initial;
`;

const H4 = styled.h4`
  font-size: ${theme("Typography.ParagraphFontSize")};
  margin: 0;
`;

const Status = styled.strong<{ isGreen: boolean }>`
  color: ${({ isGreen }) =>
    theme(isGreen ? "Colors.PrimaryGreen" : "Colors.PrimaryRed")};
`;

const formatTypeStatus = (type: string) => _.startCase(_.camelCase(type));

export interface NonconformanceDispositionProps {
  disposition: Disposition;
}

export const NonconformanceDisposition = (
  props: NonconformanceDispositionProps
) => {
  const {
    type,
    status,
    engineeringAnalysis,
    nonconformanceWorkOrders,
    signOffs,
    deviations,
    rationale,
    notes,
  } = props.disposition;
  const { linkableIds } = useLookup(
    "workOrder",
    "reportId",
    "id",
    nonconformanceWorkOrders
  );

  return (
    <Segment>
      <Card elevation={3}>
        <CardContent>
          <Segment>
            <Flex>
              <Spacer>
                <H3>{formatTypeStatus(type || "Unknown")}</H3>
                <Status isGreen={status === DispositionStatus.Closed}>
                  {formatTypeStatus(status)}
                </Status>
              </Spacer>
            </Flex>
          </Segment>
          {deviations && (
            <Block>
              <Segment>
                <Segment>
                  <H4>Deviation assessment</H4>
                </Segment>
                <Segment>
                  <Deviations deviations={deviations} />
                </Segment>
              </Segment>
            </Block>
          )}
          {rationale && (
            <Block>
              <Segment>
                <H4>Disposition rationale</H4>
              </Segment>
              <Segment>
                <JulesContent content={rationale} />
              </Segment>
            </Block>
          )}
          {notes && (
            <Block>
              <Segment>
                <H4>Disposition notes</H4>
              </Segment>
              <Segment>
                <JulesContent content={notes} />
              </Segment>
            </Block>
          )}
          {engineeringAnalysis && (
            <Block>
              <Segment>
                <H4>Engineering analysis</H4>
              </Segment>
              <Segment>
                <JulesContent content={engineeringAnalysis} />
              </Segment>
            </Block>
          )}
          {nonconformanceWorkOrders && nonconformanceWorkOrders.length > 0 && (
            <Segment>
              <Text as="div">
                <Spacer>
                  <span>Work orders:</span>
                  {nonconformanceWorkOrders.map((workOrder) => (
                    <React.Fragment key={workOrder.id}>
                      {workOrder.id && linkableIds.includes(workOrder.id) ? (
                        <Link to={Routes.workOrder(workOrder.id)}>
                          <strong>WO-{workOrder.displayId}</strong>
                        </Link>
                      ) : (
                        <span>WO-{workOrder.displayId}</span>
                      )}
                    </React.Fragment>
                  ))}
                </Spacer>
              </Text>
            </Segment>
          )}
          {signOffs && signOffs.length > 0 && (
            <>
              <Segment />
              {signOffs.map((signOff, index) => (
                <SignOff key={index} signOff={signOff} />
              ))}
            </>
          )}
        </CardContent>
      </Card>
    </Segment>
  );
};
