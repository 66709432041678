import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as Actions from "./layout-actions";
import { LayoutState, HydrationStatus } from "./layout-state";
import { LayoutFilledActionPayload } from "./layout-actions";

export const initialState: LayoutState = {
  hydration: HydrationStatus.Empty,
  title: null,
  attributes: [],
  breadcrumbs: [],
};

const LoadingTitle = "Loading...";
const TitleSuffix = "Blue Origin: Pedigree Review";

export function constructTitle(title?: string) {
  if (title) {
    return `${title} - ${TitleSuffix}`;
  }
  return TitleSuffix;
}

export const LayoutReducer = reducerWithInitialState(initialState)
  .case(Actions.setEmptyAction, (state) => {
    document.title = LoadingTitle;
    return {
      ...state,
      hydration: HydrationStatus.Empty,
      title: null,
      attributes: [],
      breadcrumbs: [],
    };
  })
  .case(Actions.setLoadingAction, (state) => {
    document.title = LoadingTitle;
    return {
      ...state,
      hydration: HydrationStatus.Loading,
      title: null,
      attributes: [],
      breadcrumbs: [],
    };
  })
  .case(
    Actions.setFilledAction,
    (state, { attributes, breadcrumbs, title }: LayoutFilledActionPayload) => {
      document.title = constructTitle(title);
      return {
        ...state,
        hydration: HydrationStatus.Filled,
        title,
        attributes: attributes || [],
        breadcrumbs: breadcrumbs || [],
      };
    }
  )
  .build();
