import {
  Block,
  ScrollNavManager,
  Segment,
  SharableLightHeading,
  Text,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import { Nonconformance } from "../../../models/generated";
import { NonconformancePageAnchors } from "./nonconformance-page";
import _ from "lodash";
import { List, ListItem } from "../../layout/list";

export const formatType = (type: string) => _.startCase(_.camelCase(type));

export interface NonconformanceProblemSourcesProps {
  manager: ScrollNavManager;
  pageUrl: string;
  problemSources: Nonconformance["problemSources"];
}

export class NonconformanceProblemSources extends React.Component<NonconformanceProblemSourcesProps> {
  public render() {
    const { manager, pageUrl, problemSources } = this.props;

    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={NonconformancePageAnchors.ProblemSources}
            pageUrl={pageUrl}
            manager={manager}
            text="Problem Sources"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        <Segment>
          {problemSources && problemSources.length > 0 ? (
            <List>
              {problemSources.map((problemSource) => (
                <ListItem key={problemSource.problemSourceId}>
                  <span>{formatType(problemSource.type)}</span>{" "}
                  {formatType(problemSource.type) !== problemSource.name && (
                    <strong>{problemSource.name}</strong>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Text>
              <em>No problem sources</em>
            </Text>
          )}
        </Segment>
      </Block>
    );
  }
}
