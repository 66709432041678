import actionCreatorFactory from "typescript-fsa";
import { UserState } from "./user-state";

const actionCreator = actionCreatorFactory();

export const setAuthenticationType = "SET_AUTHENTICATION";
export const setAuthenticationAction = actionCreator<UserState>(
  setAuthenticationType
);
export const clearAuthenticationAction = actionCreator("CLEAR_AUTHENTICATION");
