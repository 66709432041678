import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import { GlobalHeader, HeaderBreadcrumb } from "@blueorigin/blue-branding-kit";
import { Assets } from "../../assets";
import { useOktaAuth } from "@okta/okta-react";

const DEFAULT_BACKGROUND_IMAGE = Assets.BE4SmallBannerBackground;
const DEFAULT_TITLE = "My Reviews";
const NAV_BAR_ITEMS = [
  {
    name: "My Reviews",
    path: "/",
  },
];

interface HeaderComponentProps {
  title?: string | null;
  backgroundImage?: string;
  breadcrumbs?: HeaderBreadcrumb[];
  attributes?: any | null;
  userName?: string | null;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const title = props.title || DEFAULT_TITLE;
  const backgroundImage = props.backgroundImage || DEFAULT_BACKGROUND_IMAGE;
  const { breadcrumbs, attributes, userName } = props;

  const { oktaAuth } = useOktaAuth();

  const handleLogout = (): void => {
    oktaAuth.signOut();
  };

  return (
    <GlobalHeader
      title={title}
      backgroundImage={backgroundImage}
      breadcrumbs={breadcrumbs}
      attributes={attributes}
      navBarItems={NAV_BAR_ITEMS}
      userName={userName ?? undefined}
      logout={handleLogout}
    />
  );
};

export const mapStateToProps = (
  state: AppState,
  props: HeaderComponentProps
): HeaderComponentProps => {
  return {
    ...props,
    title: state.layout.title,
    breadcrumbs: state.layout.breadcrumbs,
    attributes: state.layout.attributes,
    userName: state.user.name,
  };
};

export const Header = connect(mapStateToProps)(HeaderComponent);
