import {
  Block,
  DateTime,
  PrimaryGreen,
  ScrollNavManager,
  Segment,
} from "@blueorigin/blue-branding-kit";
import { SharableLightHeading } from "@blueorigin/blue-branding-kit/components/navigation/scrollnav/share/sharable-light-heading";
import * as React from "react";
import { ChangePageAnchors } from "./change-page";
import { ChangeSummary as ChangeSummaryModel } from "../../../models/generated";
import { KeyValue } from "../../layout/key-value";
import { TimelineEdge } from "../../layout/timeline-edge";
import moment from "moment";
import { ShareDisclaimer } from "../../../constants";
import { StrongTime } from "../../layout/strong-time";

export interface ChangeSummaryProps {
  manager: ScrollNavManager;
  pageUrl: string;
  summary: ChangeSummaryModel;
  approvalTimestamp: number;
}

export class ChangeSummary extends React.Component<ChangeSummaryProps> {
  public render() {
    const { manager, pageUrl, summary, approvalTimestamp } = this.props;
    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={ChangePageAnchors.Summary}
            pageUrl={pageUrl}
            manager={manager}
            text="Summary"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        <Segment>
          <TimelineEdge
            label="Approved"
            value={
              <StrongTime>
                <DateTime when={moment.unix(approvalTimestamp)} />
              </StrongTime>
            }
            color={PrimaryGreen}
            icon="fas fa-check-circle"
          />
        </Segment>
        <KeyValue label="Initial condition" value={summary.initialCondition} />
        <KeyValue
          label="Should be condition"
          value={summary.shouldBeCondition}
        />
        <KeyValue label="Change rationale" value={summary.changeRationale} />
      </Block>
    );
  }
}
