import { combineReducers, compose, createStore } from "redux";
import { UserReducer } from "./user/user";
import { UserState } from "./user/user-state";
import { LayoutState } from "./layout/layout-state";
import { LayoutReducer } from "./layout/layout";
import { DisplayReducer } from "./display/display";
import { DisplayState } from "./display/display-state";

export interface AppState {
  user: UserState;
  layout: LayoutState;
  display: DisplayState;
}

const composeEnhancers =
  (typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default createStore(
  combineReducers({
    user: UserReducer,
    layout: LayoutReducer,
    display: DisplayReducer,
  }),
  composeEnhancers()
);
