import React from "react";
import { FauxLink } from "@blueorigin/blue-branding-kit";

export interface ClearButtonProps {
  buttonClick: () => void;
}

export const ClearButton: React.FC<ClearButtonProps> = (props) => {
  const { buttonClick } = props;

  /**
   * Handle clear button click
   */
  const onClick = () => {
    buttonClick();
  };

  return (
    <FauxLink onClick={onClick}>
      <strong>Clear</strong>
    </FauxLink>
  );
};
