import {
  Mood,
  Notice,
  ScrollNavManager,
  Segment,
  Subheading,
  Text,
  theme,
  Sharable,
  ExpansionButton,
  MixedStateExpansionContext,
  Link,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import styled from "styled-components";
import {
  Operation,
  OperationStatus,
  Skippability,
  StepStatus,
} from "../../../models/generated";
import { ShareDisclaimer } from "../../../constants";
import { WorkOrderStep } from "./work-order-step";
import { Card, CardContent, Collapse } from "@material-ui/core";
import { ShareableActions } from "../../layout/shareable-actions";
import { Spacer } from "../../layout/spacer";
import { Routes } from "../../../routes";
import { WorkOrderSkipConditions } from "./work-order-skip-conditions";

const OperationHeader = styled.div`
  position: sticky;
  top: 8rem;
  background-color: white;
  padding: 1rem 0;
  z-index: 30;
`;

const DeletedOperationHeader = styled.div`
  position: sticky;
  top: 8rem;
  background-color: white;
  z-index: 30;
`;

const OperationHeaderShadow = styled.div`
  position: sticky;
  top: 12.7rem;
  height: 1rem;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid ${theme("Colors.LightGray")};
  z-index: 0;
`;

const OperationHeaderShadowCover = styled.div`
  height: 1rem;
  top: -10px;
  width: 100%;
  position: relative;
  background-color: white;
  z-index: 20;
`;

const OperationInner = styled.div`
  margin: 0 8px;
`;

const H3 = styled(Subheading)`
  text-transform: initial;
  letter-spacing: initial;
`;

const Status = styled.strong<{ isGreen: boolean }>`
  color: ${({ isGreen }) =>
    theme(isGreen ? "Colors.PrimaryGreen" : "Colors.PrimaryRed")};
`;

export interface WorkOrderOperationProps {
  manager: ScrollNavManager;
  pageUrl: string;
  operation: Operation;
  deletedOperation?: boolean;
}

export const WorkOrderOperation = (props: WorkOrderOperationProps) => {
  const { pageUrl, manager, operation, deletedOperation } = props;
  const {
    id,
    steps,
    status,
    title,
    workCenter,
    whereFound,
    skipSettings,
  } = operation;

  const { expandedNodeIds, setExpandedNodeIds } = React.useContext(
    MixedStateExpansionContext
  );
  const expanded = expandedNodeIds.indexOf(operation.id) > -1;
  const headerRef = React.useRef<HTMLDivElement>(null);
  const innerId = `operation-inner-${id}`;
  const handleCollapse = () =>
    setExpandedNodeIds!(
      expandedNodeIds.filter((expandedId) => expandedId !== id)
    );
  const handleExpand = () => setExpandedNodeIds!([...expandedNodeIds, id]);
  const OperationHeaderDisplay =
    deletedOperation && !expandedNodeIds.includes(operation.id)
      ? DeletedOperationHeader
      : OperationHeader;
  return (
    <Segment>
      <OperationHeaderDisplay
        ref={headerRef}
        data-testid={`operation-header-${id}`}
      >
        <Segment>
          <Sharable
            id={id}
            pageUrl={pageUrl}
            manager={manager}
            disclaimer={ShareDisclaimer}
            subject={<H3>{title}</H3>}
            actions={
              <ShareableActions>
                <ExpansionButton
                  expanded={expanded}
                  aria-controls={innerId}
                  aria-label={title}
                  onCollapse={handleCollapse}
                  onExpand={handleExpand}
                />
              </ShareableActions>
            }
          />
        </Segment>
        <Segment>
          <Spacer>
            <span>
              Status{" "}
              <strong>
                <Status
                  isGreen={
                    status === OperationStatus.Completed ||
                    status === OperationStatus.Skipped
                  }
                >
                  {status}
                </Status>
              </strong>
            </span>
            {workCenter && (
              <span>
                Work Center{" "}
                <strong>
                  {workCenter.buildingSiteName}: {workCenter.buildingName}:{" "}
                  {workCenter.name}
                </strong>
              </span>
            )}
          </Spacer>
        </Segment>
      </OperationHeaderDisplay>
      <OperationHeaderShadow />
      <OperationHeaderShadowCover />

      <Collapse in={expanded} aria-hidden={expanded}>
        <OperationInner id={innerId}>
          {status === OperationStatus.Skipped && (
            <Segment>
              <Notice mood={Mood.COOL} title="This operation was skipped">
                <Text>
                  Not all work was completed. Each step is marked to indicate
                  which were complete and which were not.
                </Text>
              </Notice>
            </Segment>
          )}
          {whereFound && whereFound.length > 0 && (
            <Segment>
              <Card elevation={3}>
                <CardContent>
                  <Segment>
                    <strong>NC found on this operation</strong>
                  </Segment>
                  {whereFound.map((report) => (
                    <Segment key={report.reportId}>
                      <Link to={Routes.nonconformance(report.reportId)}>
                        <strong>{report.reportId}</strong>
                      </Link>
                    </Segment>
                  ))}
                </CardContent>
              </Card>
            </Segment>
          )}
          {skipSettings?.skippability === Skippability.SKIPPABLE &&
            skipSettings?.skippingCriteria.length > 0 && (
              <Segment>
                <Card elevation={3}>
                  <CardContent>
                    <WorkOrderSkipConditions
                      skippingCriteria={skipSettings.skippingCriteria}
                      isOperation
                    />
                  </CardContent>
                </Card>
              </Segment>
            )}

          {steps &&
            steps.length > 0 &&
            steps.map(
              (step) =>
                step.status !== StepStatus.Deleted && (
                  <WorkOrderStep
                    step={step}
                    key={step.id}
                    showStatus={status === OperationStatus.Completed}
                  />
                )
            )}
        </OperationInner>
      </Collapse>
    </Segment>
  );
};
