import React from "react";
import { InputBase, InputAdornment } from "@material-ui/core";
import { Icon, Gray, PrimaryBlue } from "@blueorigin/blue-branding-kit";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";

export const GrayIcon = styled(Icon)`
  color: ${Gray};
`;

export const BlueIcon = styled(Icon)`
  color: ${PrimaryBlue};
`;

const PartInput = withStyles({
  input: {
    "&::placeholder": {
      color: Gray,
      opacity: 1,
      fontSize: "1.25rem",
      fontWeight: 500,
    },
  },
})(InputBase);

export interface SearchInputProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  partValue: string;
  onValueChange: (value: string, name: string) => void;
  setFocus?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { open, onOpenChange, partValue, onValueChange, setFocus } = props;

  /**
   * Handle search input change
   * @param event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value, event.target.name);
  };

  /**
   * Handles focus on search input
   * @param event
   */
  const PartInputFocus = (event: React.FocusEventHandler<HTMLInputElement>) => {
    onOpenChange(true);
  };

  return (
    <PartInput
      aria-controls="search-input"
      aria-label="part search"
      id="part-input"
      autoComplete="off"
      onChange={handleChange}
      name="part"
      inputRef={(input) => input && setFocus && input.focus()}
      fullWidth
      placeholder="Enter a part number, serial number, etc."
      startAdornment={
        <InputAdornment position="start">
          {!open ? (
            <GrayIcon name="fas fa-lg fa-search" />
          ) : (
            <BlueIcon name="fas fa-lg fa-search" />
          )}
        </InputAdornment>
      }
      inputProps={{
        onFocus: (event: any) => PartInputFocus(event),
        "aria-label": "part number search",
        style: {
          textOverflow: "ellipsis",
          width: "100%",
        },
      }}
      value={partValue}
    />
  );
};
