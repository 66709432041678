import _ from "lodash";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

export interface DisplayToggleProps {
  /** displayId: identifies each display toggle with reference to child element */
  displayId: string;
  /** children: react element(s) to be shown/hid */
  children: React.ReactElement | null;
}

/**
 * Display Toggle Component is a wrap used by component(s)
 * to control display state.
 * @param props
 * @returns child element(s) or null
 */
export const DisplayToggle: React.FC<DisplayToggleProps> = (props) => {
  const { displayId, children } = props;
  const show = useSelector((state: RootStateOrAny) =>
    _.get(state.display.show_list, displayId)
  );
  return show ? children : null;
};
