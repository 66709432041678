/**
 * Routes
 *
 * Object to construct routes within the application
 */
export const Routes = {
  workOrder: (id: string, partInstanceId?: string) =>
    `${
      partInstanceId ? `/part-instance/${partInstanceId}` : ""
    }/work-order/${id}`,
  nonconformance: (id: string, partInstanceId?: string) =>
    `${
      partInstanceId ? `/part-instance/${partInstanceId}` : ""
    }/nonconformance/${id}`,
  myReviews: "/",
  partInstance: (id: string) => `/part-instance/${id}`,
  engineeringChange: (id: string, partInstanceId?: string) =>
    `${
      partInstanceId ? `/part-instance/${partInstanceId}` : ""
    }/engineering-change/${id}`,
  file: (id: string, partInstanceId?: string) =>
    `${partInstanceId ? `/part-instance/${partInstanceId}` : ""}/file/${id}`,
  contact: "mailto:pedigreesupport@blueorigin.com",
};
