import {
  Block,
  ScrollNavManager,
  Segment,
  SharableLightHeading,
  Text,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import { Nonconformance } from "../../../models/generated";
import { Spacer } from "../../layout/spacer";
import { NonconformancePageAnchors } from "./nonconformance-page";

export interface NonconformanceContainmentProps {
  manager: ScrollNavManager;
  pageUrl: string;
  containment: Nonconformance["containment"];
}

export class NonconformanceContainment extends React.Component<NonconformanceContainmentProps> {
  public render() {
    const { manager, pageUrl, containment } = this.props;

    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={NonconformancePageAnchors.Containment}
            pageUrl={pageUrl}
            manager={manager}
            text="Containment"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        {containment &&
        (containment.containmentRequired != null ||
          (containment.whereFound && containment.whereFound.length > 0)) ? (
          <>
            {containment.containmentRequired != null && (
              <Segment>
                <Text as="div">
                  <Spacer>
                    <span>Containment / stock check is:</span>
                    <strong>
                      {containment.containmentRequired
                        ? "Required"
                        : "Not required"}
                    </strong>
                  </Spacer>
                </Text>
              </Segment>
            )}
            {containment.whereFound && containment.whereFound.length > 0 && (
              <Segment>
                <Text as="div">
                  <Spacer>
                    <span>Tickets:</span>
                    {containment.whereFound.map((item) => (
                      <span key={item}>{item}</span>
                    ))}
                  </Spacer>
                </Text>
              </Segment>
            )}
          </>
        ) : (
          <Segment>
            <Text>
              <em>No containment data</em>
            </Text>
          </Segment>
        )}
      </Block>
    );
  }
}
