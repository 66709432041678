import { AppLoading } from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { useSelector } from "react-redux";
import "../assets/font-awesome/css/all.css";
import { Footer } from "./layout/footer";
import { Header } from "./layout/header";
import { PageRouter } from "./pages/page-router";
import { AppState } from "../redux/store";
import { HydrationStatus } from "../redux/layout/layout-state";
import ScrollToTop from "./scroll-to-top";
import { appConfig } from "../config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  concat,
  createHttpLink,
} from "@apollo/client";
import { ContextSetter, setContext } from "@apollo/client/link/context";
import { setAuthenticationType } from "../redux/user/user-actions";
import {
  useDispatchUser,
  useRequireAuth,
} from "@blueorigin/authentication-library";
import { SearchBar } from "./layout/search/search-bar";
import { DisplayToggle } from "./layout/display-toggle";
import { DisplayComponent } from "../constants";

export const useRequireAuthApollo = () => {
  const { loading, accessToken } = useRequireAuth();
  const { client, getHeaders } = React.useMemo(() => {
    const getHeaders: ContextSetter = (operation, { headers }) => {
      return {
        headers: accessToken
          ? {
              ...headers,
              "x-client-id": appConfig.okta.clientId,
              authorization: accessToken,
            }
          : headers,
      };
    };
    return {
      client: new ApolloClient({
        link: concat(
          setContext(getHeaders),
          createHttpLink({
            uri: appConfig.apolloUri,
          })
        ),
        cache: new InMemoryCache(),
      }),
      getHeaders,
    };
  }, [accessToken]);

  return { getHeaders, client, loading };
};

export const Wrapper = () => {
  const hydration = useSelector<AppState>((state) => state.layout.hydration);
  useDispatchUser(setAuthenticationType);
  const { client, loading } = useRequireAuthApollo();

  if (loading) {
    return <AppLoading />;
  }

  // When loading is in the empty state, no content is displayed, and instead a loading icon is shown.
  const empty = hydration === HydrationStatus.Empty;
  return (
    <ApolloProvider client={client}>
      <div style={{ display: empty ? "none" : "block" }}>
        <Header />
        <DisplayToggle displayId={DisplayComponent.searchBar}>
          <SearchBar />
        </DisplayToggle>
        <ScrollToTop />
        <PageRouter />
        <Footer />
      </div>
      {empty && <AppLoading />}
    </ApolloProvider>
  );
};
