import {
  ContentPolicyPurifiedHTML,
  UserContent,
  Text,
} from "@blueorigin/blue-branding-kit";
import DOMPurify from "dompurify";
import * as React from "react";
import styled from "styled-components";

const Container = styled(Text).attrs({ as: "div" })`
  p {
    margin: 0;
  }
  p + p {
    margin-top: 1em;
  }
`;

// Remove empty paragraphs from Jules HTML
DOMPurify.addHook("afterSanitizeElements", (node) => {
  if (node.nodeName === "P" && /^((&nbsp;)|(<br>)|\s)*$/.test(node.innerHTML)) {
    node.remove();
  }
});

/*
  Everything between here and the component is from:
  https://github.com/cure53/DOMPurify/blob/main/demos/hooks-sanitize-css-demo.html
*/

const allowedCSSProperties = [
  "color",
  "background",
  "background-color",
  "border",
  "content",
  "text-decoration",
];

/**
 *  Take CSS property-value pairs and validate against allow-list,
 *  then add the styles to an array of property-value pairs
 */
const validateStyles = (output: string[], styles: CSSStyleDeclaration) => {
  // Validate regular CSS properties
  for (const prop in styles) {
    if (typeof styles[prop] === "string") {
      if (styles[prop] && allowedCSSProperties.indexOf(prop) > -1) {
        output.push(prop + ":" + styles[prop] + ";");
      }
    }
  }
};

// Type guard for elements with inline styles
const hasStyleAttribute = (
  node: Element
): node is Element & ElementCSSInlineStyle => node.hasAttribute("style");

// Add a hook to enforce CSS attribute sanitization
DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  // Check all style attribute values and validate them
  if (hasStyleAttribute(node)) {
    const output: string[] = [];
    validateStyles(output, node.style);
    // re-add styles in case any are left
    if (output.length) {
      node.setAttribute("style", output.join(""));
    } else {
      node.removeAttribute("style");
    }
  }
});

export interface JulesContentProps {
  content: string;
}

export const JulesContent = (props: JulesContentProps) => {
  return (
    <Container>
      <UserContent
        policy={ContentPolicyPurifiedHTML}
        content={props.content}
        config={{
          FORBID_TAGS: ["style", "img", "a"],
        }}
      />
    </Container>
  );
};
