import {
  Block,
  MixedStateExpansionContext,
  MixedStateExpansionContextProvider,
  MixedStateExpansionButton,
  LightHeading,
  MinimumButton,
  ScrollNavManager,
  Segment,
  Sharable,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import { WorkOrderPageAnchors } from "./work-order-page";
import { WorkOrderInstance } from "../../../models/generated";
import { WorkOrderOperation } from "./work-order-operation";
import { ShareableActions } from "../../layout/shareable-actions";
import { Hoverable } from "../../layout/hoverable";

export interface WorkOrderOperationsDeletedProps {
  manager: ScrollNavManager;
  pageUrl: string;
  operations: WorkOrderInstance["operations"];
}

export const WorkOrderOperationsInnerDeleted = (
  props: WorkOrderOperationsDeletedProps
) => {
  const { manager, pageUrl, operations } = props;

  const {
    setExpandableNodeIds,
    setExpandedNodeIds,
    expandableNodeIds,
  } = React.useContext(MixedStateExpansionContext);

  React.useEffect(() => {
    const deletedNodeIds =
      operations
        ?.filter((operation) => operation.title === "Deleted")
        .map((operation) => operation.id) || [];

    setExpandableNodeIds?.(deletedNodeIds);
    setExpandedNodeIds?.([]);
  }, [setExpandedNodeIds, setExpandableNodeIds, operations]);

  const handleExpand = () => setExpandedNodeIds!(expandableNodeIds);
  const handleCollapse = () => setExpandedNodeIds!([]);

  const deletedOperations = operations?.filter(
    (operation) => operation.title === "Deleted"
  );

  return (
    <>
      <Segment>
        <Sharable
          id={WorkOrderPageAnchors.DeletedOperations}
          pageUrl={pageUrl}
          manager={manager}
          disclaimer={ShareDisclaimer}
          subject={
            <LightHeading>
              {deletedOperations && deletedOperations.length > 0
                ? deletedOperations.length
                : "No"}{" "}
              Deleted Operations
            </LightHeading>
          }
          actions={
            <MixedStateExpansionContext.Consumer>
              {({ expandableNodeIds, canExpand, canCollapse }) =>
                expandableNodeIds.length > 0 && (
                  <ShareableActions>
                    <MixedStateExpansionButton
                      aria-controls="operations-deleted"
                      labelSuffix="all deleted operations"
                      canExpand={canExpand}
                      canCollapse={canCollapse}
                      onCollapse={handleCollapse}
                      onExpand={handleExpand}
                    />
                  </ShareableActions>
                )
              }
            </MixedStateExpansionContext.Consumer>
          }
        />
      </Segment>
      <Segment>
        <div id="operations-deleted">
          {deletedOperations &&
            deletedOperations.map((operation) => (
              <WorkOrderOperation
                key={operation.id}
                operation={operation}
                pageUrl={pageUrl}
                manager={manager}
                deletedOperation={true}
              />
            ))}
        </div>
      </Segment>
    </>
  );
};

export const WorkOrderOperationsDeleted = (
  props: WorkOrderOperationsDeletedProps
) => {
  return (
    <Hoverable component={MinimumButton}>
      <Block extraPadding border={false}>
        <MixedStateExpansionContextProvider>
          <WorkOrderOperationsInnerDeleted {...props} />
        </MixedStateExpansionContextProvider>
      </Block>
    </Hoverable>
  );
};
