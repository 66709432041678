import React from "react";
import { Icon, theme } from "@blueorigin/blue-branding-kit";
import { TableRow } from "@material-ui/core";
import styled from "styled-components";
import { IconTableCell, BlueTableCell, SearchTableCell } from "./search-result";

const ButtonImage = styled.div`
  background-color: ${theme("Colors.LightGray")};
  border-radius: 30px;
  padding: 1px 14px;
  display: table-cell;
  font-size: 0.875rem;
`;

const LotNoExample = styled.div`
  padding-left: 10px;
  display: table-cell;
`;

export const SearchResultHint = () => {
  return (
    <>
      <TableRow>
        <IconTableCell>
          <Icon name="fa fa-2x fa-cog"></Icon>
        </IconTableCell>
        <BlueTableCell>PARTS</BlueTableCell>
        <SearchTableCell></SearchTableCell>
        <SearchTableCell>
          <strong>Example: </strong>004-100-0000-101
        </SearchTableCell>
        <SearchTableCell></SearchTableCell>
      </TableRow>
      <TableRow>
        <IconTableCell>
          <Icon name="fa fa-2x fa-cog"></Icon>
        </IconTableCell>
        <BlueTableCell>PART INSTANCES</BlueTableCell>
        <SearchTableCell></SearchTableCell>
        <SearchTableCell>
          <strong>Example: </strong>004-100-0000-101
        </SearchTableCell>
        <SearchTableCell>
          <ButtonImage>
            <strong>Enter</strong>
          </ButtonImage>
          <LotNoExample>002</LotNoExample>
        </SearchTableCell>
      </TableRow>
    </>
  );
};
