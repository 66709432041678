import {
  AnchorLinkUndecorated,
  Block,
  DateTime,
  FileThumb,
  Icon,
  PrimaryBlue,
  ScrollNavManager,
  Segment,
  SharableLightHeading,
  theme,
  PrimaryGreen,
  Subheading,
} from "@blueorigin/blue-branding-kit";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { ShareDisclaimer } from "../../../constants";
import { DispositionStatus, Nonconformance } from "../../../models/generated";
import { JulesContent } from "../../layout/jules-content";
import { Spacer } from "../../layout/spacer";
import { StrongTime } from "../../layout/strong-time";
import { TimelineEdge } from "../../layout/timeline-edge";
import { NonconformancePageAnchors } from "./nonconformance-page";
import _ from "lodash";

const WhereFoundIcon = styled(Icon)`
  color: ${theme("Colors.PrimaryBlue")};
`;

const H3 = styled(Subheading)`
  text-transform: initial;
  letter-spacing: initial;
`;

const formatType = (type: string) => _.startCase(_.camelCase(type));

export interface NonconformanceSummaryProps {
  manager: ScrollNavManager;
  pageUrl: string;
  summary: Nonconformance["summary"];
  dispositions: Nonconformance["dispositions"];
}
export class NonconformanceSummary extends React.Component<NonconformanceSummaryProps> {
  public render() {
    const { manager, pageUrl, summary, dispositions } = this.props;
    const completedDispositions = dispositions?.filter(
      (disposition) => disposition.status === DispositionStatus.Closed
    );
    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={NonconformancePageAnchors.Summary}
            pageUrl={pageUrl}
            manager={manager}
            text="Summary"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        {summary.whereFound && summary.whereFound.length > 0 && (
          <Segment>
            <Spacer>
              <WhereFoundIcon name="fas fa-exclamation-circle"></WhereFoundIcon>
              {summary.whereFound.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </Spacer>
          </Segment>
        )}
        <Segment>
          <TimelineEdge
            label="Created"
            value={
              <StrongTime>
                <DateTime when={moment.unix(summary.createdOn)} />
              </StrongTime>
            }
            color={PrimaryBlue}
            icon="fas fa-circle"
          />
        </Segment>
        {summary.closedOn && (
          <Segment>
            <TimelineEdge
              label="Closed"
              value={
                <StrongTime>
                  <DateTime when={moment.unix(summary.closedOn)} />
                </StrongTime>
              }
              color={PrimaryBlue}
              icon="fas fa-circle"
              track
            />
          </Segment>
        )}
        {completedDispositions &&
          completedDispositions.length > 0 &&
          completedDispositions.map((disposition, index) => (
            <Segment key={disposition.dispositionId}>
              <TimelineEdge
                label={
                  index === 0 ? "Active Disposition" : "Disposition complete"
                }
                value={
                  <>
                    <strong>{formatType(disposition.type || "Unknown")}</strong>
                    <strong>{" | Closed"}</strong>
                    <strong>{index > 0 ? " - SUPERSEDED" : ""}</strong>
                  </>
                }
                color={PrimaryGreen}
                icon="fas fa-check-circle"
              />
            </Segment>
          ))}
        <Segment>
          <H3>Initial condition</H3>
        </Segment>
        <Segment>
          <JulesContent content={summary.initialCondition} />
        </Segment>
        <Segment>
          <H3>Should be condition</H3>
        </Segment>
        <Segment>
          <JulesContent content={summary.shouldBeCondition} />
        </Segment>
        {summary.resolveBy && summary.resolveBy.length > 0 && (
          <>
            <Segment>
              <H3>When to resolve by</H3>
            </Segment>
            <Segment>
              {summary.resolveBy.map((item, index) => (
                <JulesContent key={index} content={item} />
              ))}
            </Segment>
          </>
        )}
        {summary.attachments && summary.attachments.length > 0 && (
          <Segment>
            {summary.attachments.map(({ fileName, url }) => (
              <AnchorLinkUndecorated
                key={url}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileThumb file={{ name: fileName, url }}></FileThumb>
              </AnchorLinkUndecorated>
            ))}
          </Segment>
        )}
      </Block>
    );
  }
}
