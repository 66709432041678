import {
  Block,
  ScrollNavManager,
  Segment,
  SharableLightHeading,
  Text,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import { Nonconformance } from "../../../models/generated";
import { NonconformanceDisposition } from "./nonconformance-disposition";
import { NonconformancePageAnchors } from "./nonconformance-page";

export interface NonconformanceDispositionsProps {
  manager: ScrollNavManager;
  pageUrl: string;
  dispositions: Nonconformance["dispositions"];
}

export class NonconformanceDispositions extends React.Component<NonconformanceDispositionsProps> {
  public render() {
    const { manager, pageUrl, dispositions } = this.props;

    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={NonconformancePageAnchors.Dispositions}
            pageUrl={pageUrl}
            manager={manager}
            text="Dispositions"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        {dispositions && dispositions.length > 0 ? (
          dispositions.map((disposition) => (
            <NonconformanceDisposition
              key={disposition.dispositionId}
              disposition={disposition}
            />
          ))
        ) : (
          <Segment>
            <Text>
              <em>No dispositions</em>
            </Text>
          </Segment>
        )}
      </Block>
    );
  }
}
