import {
  Block,
  Link,
  ScrollNavManager,
  Segment,
  SharableLightHeading,
  Text,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { Routes } from "../../../routes";
import { ShareDisclaimer } from "../../../constants";
import { Nonconformance, DefectivePart } from "../../../models/generated";
import { List, ListItem } from "../../layout/list";
import { NonconformancePageAnchors } from "./nonconformance-page";
import { useLookup } from "../../../hooks/use-lookup";

export interface NonconformanceDefectivePartProps {
  defectivePart: DefectivePart;
}

export interface DefectivePartsProps {
  defectiveParts: Nonconformance["defectiveParts"];
}

export interface NonconformanceDefectivePartsProps {
  manager: ScrollNavManager;
  pageUrl: string;
  defectiveParts: Nonconformance["defectiveParts"];
}

export const NonconformanceDefectiveParts: React.FC<NonconformanceDefectivePartsProps> = (
  props
) => {
  const { manager, pageUrl, defectiveParts } = props;
  const { linkableIds } = useLookup(
    "partInstance",
    "partInstanceId",
    "partInstanceId",
    defectiveParts
  );

  return (
    <Block extraPadding>
      <Segment>
        <SharableLightHeading
          id={NonconformancePageAnchors.DefectiveParts}
          pageUrl={pageUrl}
          manager={manager}
          text="Defective Parts"
          disclaimer={ShareDisclaimer}
        />
      </Segment>
      <Segment>
        {defectiveParts && defectiveParts.length > 0 ? (
          <List>
            {defectiveParts.map((defectivePart, index) => (
              <ListItem key={index}>
                {defectivePart.partInstanceId &&
                linkableIds.includes(defectivePart.partInstanceId) ? (
                  <Link to={Routes.partInstance(defectivePart.partInstanceId!)}>
                    <strong>{defectivePart.partName}</strong>
                  </Link>
                ) : (
                  <strong>{defectivePart.partName}</strong>
                )}
                <span>
                  P/N <strong>{defectivePart.partNumber}</strong>
                </span>
                {defectivePart.lotNumber && (
                  <span>
                    L/N <strong>{defectivePart.lotNumber}</strong>
                  </span>
                )}
                {defectivePart.serialNumber && (
                  <span>
                    S/N <strong>{defectivePart.serialNumber}</strong>
                  </span>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <Text>
            <em>No defective parts</em>
          </Text>
        )}
      </Segment>
    </Block>
  );
};
