import _ from "lodash";
import { Routes } from "../../routes";

//checks to see if value exists for attribute; if not, will return null
export const createAttribute = (
  label: string,
  value?: string | null,
  reverseStyling?: boolean
) => {
  if (value) {
    return { label, value, ReverseStyle: reverseStyling };
  }
  return null;
};

//creates an array of possible attributes. _.compact removes falsey values from array,(ex.null returned if no serialNumber)
export const configureAttributes = (partInstance: any) => {
  const attributes = _.concat(
    createAttribute("P/N", partInstance.partNumber),
    createAttribute("S/N", partInstance.serialNumber),
    createAttribute("L/N", partInstance.lotNumber),
    createAttribute("Version", partInstance.partVersion)
  );
  return _.compact(attributes);
};

export const configureBreadcrumbs = (
  data?: any,
  dropLastDetailsPage = true
) => {
  const minimumDataLength = dropLastDetailsPage ? 1 : 0;
  const reviewPageBreadcrumb = [
    {
      name: "My Reviews",
      path: "/",
    },
  ];
  if (data && data.length > minimumDataLength) {
    const detailsPageBreadcrumbs = _.map(data, function (breadcrumb) {
      return {
        name: breadcrumb.partName,
        path: Routes.partInstance(breadcrumb.partInstanceId),
      };
    });
    return _.concat(
      reviewPageBreadcrumb,
      dropLastDetailsPage
        ? _.dropRight(detailsPageBreadcrumbs)
        : detailsPageBreadcrumbs
    );
  }
  return reviewPageBreadcrumb;
};
