import {
  Block,
  Column,
  ColumnWidth,
  Container,
  Row,
  ScrollNav,
  ScrollNavItem,
  ScrollNavManager,
  Segment,
  Sticky,
  Error,
  MinimumButton,
} from "@blueorigin/blue-branding-kit";
import { pdf } from "@react-pdf/renderer";
import { LightHeading } from "@blueorigin/blue-branding-kit/components/text/light-heading";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { appConfig } from "../../../config";
import { PartInstance, WorkOrderStatus } from "../../../models/generated";
import { Routes } from "../../../routes";
import {
  setFilledAction,
  LayoutFilledActionPayload,
} from "../../../redux/layout/layout-actions";
import { setDisplayAction } from "../../../redux/display/display-actions";
import { DisplayState } from "../../../redux/display/display-state";
import { ActionCreator } from "typescript-fsa";
import { configureBreadcrumbs } from "../page-header-manager";
import { WorkOrderSummary } from "./work-order-summary";
import { WorkOrderBillOfMaterials } from "./work-order-bom";
import { WorkOrderOperations } from "./work-order-operations";
import { WorkOrderInstance } from "../../../models/generated";
import { gql, QueryResult } from "@apollo/client";
import { BreadcrumbsPartInstanceFragment } from "../../../shared-queries";
import { Query } from "@apollo/client/react/components";
import { PageLoading } from "@blueorigin/blue-branding-kit/components/loading/page-loading";
import { DisplayComponent } from "../../../constants";
import { WorkOrderOperationsDeleted } from "./work-order-operations-deleted";
import { ExportedWO } from "../export-util";
import saveAs from "file-saver";

export const WorkOrderFragment = gql`
  fragment workOrder on Query {
    workOrder(reportId: $reportId) {
      reportId
      displayId
      title
      status
      summary {
        reportId
        quantity
        partName
        partNumber
        lotNumbers
        serialNumbers
        version
        startTimestamp
        completedTimestamp
        notes
        attachments {
          mimeType
          fileName
          fileSizeBytes
          url
        }
      }
      billOfMaterials {
        id
        partName
        partNumber
        serialNumber
        lotNumbers
        partInstanceId
        version
        quantity
        subPartIds
        action
        deletedOn
        replacePartInstanceId
      }
      operations {
        id
        displayId
        title
        status
        skipSettings {
          skippability
          skippingCriteria
        }
        workCenter {
          id
          name
          activeStatus
          buildingName
          buildingSiteName
        }
        whereFound {
          reportId
        }
        steps {
          id
          displayId
          instructions
          executionNotes
          status
          attachments {
            mimeType
            fileName
            fileSizeBytes
            url
          }
          executionAttachments {
            mimeType
            fileName
            fileSizeBytes
            url
          }
          skipSettings {
            skippability
            skippingCriteria
          }
          closeOuts {
            type
            title
            description
            data
            unit
            prefix
            suffix
            attachments {
              mimeType
              fileName
              fileSizeBytes
              url
            }
          }
          signOffs {
            status
            role
            timestamp
          }
        }
      }
    }
  }
`;

export const WorkOrderPageWithPartQuery = gql`
  ${WorkOrderFragment}
  ${BreadcrumbsPartInstanceFragment}
  query Q($reportId: String!, $partInstanceId: String!) {
    ...workOrder
    ...partInstance
  }
`;

export const WorkOrderPageQuery = gql`
  ${WorkOrderFragment}
  query Q($reportId: String!) {
    ...workOrder
  }
`;

const ERROR_TITLE = "Work Order Not Found";

export const WorkOrderPageAnchors = {
  Summary: "summary",
  BillOfMaterials: "bom",
  Operations: "operations",
  DeletedOperations: "deletedoperations",
};

export const formatStatus = (status: WorkOrderStatus) =>
  status === WorkOrderStatus.OnHold ? "On Hold" : status;

export interface WorkOrderPageProps
  extends RouteComponentProps<{ reportId: string; partInstanceId?: string }> {
  setFilledAction: (payload: LayoutFilledActionPayload) => void;
  setDisplayAction: ActionCreator<DisplayState>;
}

export class WorkOrderPageComponent extends React.Component<WorkOrderPageProps> {
  public manager: ScrollNavManager;

  public constructor(props: WorkOrderPageProps) {
    super(props);
    this.manager = new ScrollNavManager({ offset: -100 });
  }

  public componentDidMount() {
    this.configureSearchBar();
  }

  public render() {
    const { reportId, partInstanceId } = this.props.match.params;
    return (
      <Query
        query={partInstanceId ? WorkOrderPageWithPartQuery : WorkOrderPageQuery}
        variables={partInstanceId ? { reportId, partInstanceId } : { reportId }}
      >
        {this.renderQuery}
      </Query>
    );
  }

  public renderQuery = ({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<{
    workOrder: WorkOrderInstance;
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">;
  }>) => {
    if (loading) {
      return <PageLoading />;
    }

    if (error || !data?.workOrder) {
      this.configureHeader();
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load work order page
        </Error>
      );
    }

    const { workOrder, partInstance } = data;
    this.configureHeader(workOrder, partInstance);

    const pageUrl = `${appConfig.appUri}${Routes.workOrder(
      workOrder.reportId,
      partInstance?.partInstanceId
    )}`;
    const { manager } = this;
    const { summary, billOfMaterials, operations, type } = workOrder;

    const downloadPDF = async () => {
      const blob = await pdf(
        <ExportedWO workOrder={data.workOrder} />
      ).toBlob();
      saveAs(blob, `WO-${data.workOrder.displayId}`);
    };
    return (
      <Container>
        <Row>
          <Column width={ColumnWidth.fillWidth}>
            <WorkOrderSummary
              manager={manager}
              pageUrl={pageUrl}
              summary={summary}
              type={type}
            />
            <WorkOrderBillOfMaterials
              manager={manager}
              pageUrl={pageUrl}
              billOfMaterials={billOfMaterials}
            />
            <WorkOrderOperations
              manager={manager}
              pageUrl={pageUrl}
              operations={operations}
            />
            <WorkOrderOperationsDeleted
              manager={manager}
              pageUrl={pageUrl}
              operations={operations}
            ></WorkOrderOperationsDeleted>
          </Column>
          <Column width="300px">
            <Sticky top="6rem">
              <Block extraPadding border={false}>
                <ScrollNav manager={manager}>
                  <Segment>
                    <LightHeading>Navigation</LightHeading>
                  </Segment>
                  <Segment>
                    <ScrollNavItem
                      id={WorkOrderPageAnchors.Summary}
                      manager={manager}
                    >
                      Summary
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={WorkOrderPageAnchors.BillOfMaterials}
                      manager={manager}
                    >
                      Bill of Materials
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={WorkOrderPageAnchors.Operations}
                      manager={manager}
                    >
                      Operations
                    </ScrollNavItem>
                    <MinimumButton
                      color="primary"
                      variant="contained"
                      style={{ marginTop: "10px" }}
                      onClick={() => downloadPDF()}
                    >
                      Export all
                    </MinimumButton>
                  </Segment>
                </ScrollNav>
              </Block>
            </Sticky>
          </Column>
        </Row>
      </Container>
    );
  };

  public configureSearchBar = () => {
    this.props.setDisplayAction({
      payload: DisplayComponent.searchBar,
    });
  };

  public configureHeader = (
    workOrder?: WorkOrderInstance,
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">
  ) => {
    this.props.setFilledAction({
      title: workOrder
        ? `WO-${workOrder.displayId}: ${workOrder.title}`
        : ERROR_TITLE,
      attributes: workOrder
        ? [
            {
              label: "Status:",
              value: formatStatus(workOrder.status),
            },
          ]
        : undefined,
      breadcrumbs: workOrder
        ? configureBreadcrumbs(partInstance?.ancestry, false)
        : undefined,
    });
  };
}

export const WorkOrderPage = connect(null, {
  setFilledAction,
  setDisplayAction,
})(withRouter<any, any>(WorkOrderPageComponent));
