import {
  Segment,
  theme,
  FileIcon,
  PrimaryBlue,
  formatFileSize,
} from "@blueorigin/blue-branding-kit";
import { LABEL_MAP } from "@blueorigin/blue-branding-kit/utils/files";
import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { FileComponentProps } from "./file-page";

const FileZone = styled.div`
  padding: 5rem ${theme("Dimensions.DoubleSpace")};
  border: 4px solid ${theme("Colors.LightGray")};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const FileName = styled.div`
  font-weight: 800;
  font-size: ${theme("Typography.SubheadingFontSize")};
`;

const FileAttributes = styled.div`
  font-size: ${theme("Typography.SubheadingFontSize")};
`;

export const FileDownload = (props: FileComponentProps) => {
  const { fileType, file } = props;
  const { mimeType, fileName, url, fileSizeBytes } = file;

  // Find the label for the associated `FileType`
  const fileLabel = LABEL_MAP[fileType];
  // Add label and fileSize (if non-nullish) to array of attributes
  const attributes = [fileLabel];
  if (fileSizeBytes != null) {
    attributes.push(formatFileSize(fileSizeBytes));
  }

  return (
    <Segment>
      <FileZone>
        <Segment>
          <FileIcon mimeType={mimeType} size="5rem" color={PrimaryBlue} />
        </Segment>
        <Segment>
          <FileName>{fileName}</FileName>
        </Segment>
        <Segment>
          <FileAttributes>{attributes.join(" \u2022 ")}</FileAttributes>
        </Segment>
        <Segment>
          <Button
            variant="contained"
            color="primary"
            href={url}
            download
            target="_blank"
            rel="noopener"
          >
            Download
          </Button>
        </Segment>
      </FileZone>
    </Segment>
  );
};
