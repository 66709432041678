import {
  Block,
  ScrollNavManager,
  Segment,
  DataTreeView,
  MixedStateExpansionContextProvider,
  RenderTree,
  Text,
  LightHeading,
  MixedStateExpansionContext,
  TreeViewExpansionButton,
  MinimumButton,
  Sharable,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import {
  BillOfMaterialItem,
  WorkOrderInstance,
} from "../../../models/generated";
import { WorkOrderPageAnchors } from "./work-order-page";
import { Hoverable } from "../../layout/hoverable";
import _ from "lodash";
import { ShareableActions } from "../../layout/shareable-actions";
import { Routes } from "../../../routes";
import { useLookup } from "../../../hooks/use-lookup";

export interface BillOfMaterialItemResolved extends BillOfMaterialItem {
  subParts?: BillOfMaterialItemResolved[];
}

const resolveSubParts = (
  items: BillOfMaterialItem[]
): BillOfMaterialItemResolved[] => {
  // Deep copy items for mutations
  const resolvedItems: BillOfMaterialItemResolved[] = _.cloneDeep(items);
  // Store a list of items that shall be roots
  const rootItems: Set<BillOfMaterialItemResolved> = new Set(resolvedItems);
  // Store all parts in a lookup map by `partInstanceId`
  const partMap: Map<string, BillOfMaterialItemResolved> = new Map(
    resolvedItems.map((item) => [item.id, item])
  );
  // Resolve children
  resolvedItems.forEach((item) => {
    item.subParts = item.subPartIds
      ?.map((partId) => {
        const subPart = partMap.get(partId);
        // Delete from `rootItems` if a child
        rootItems.delete(subPart!);
        return subPart;
      })
      .filter((item) => Boolean(item)) as BillOfMaterialItemResolved[];
  });
  return Array.from(rootItems);
};

const assembleRenderTree = (
  items: BillOfMaterialItemResolved[],
  linkableIds: string[]
): RenderTree[] =>
  items.map((item) => ({
    id: item.id,
    name: item.partName,
    href:
      item.partInstanceId && linkableIds.includes(item.partInstanceId)
        ? Routes.partInstance(item.partInstanceId)
        : undefined,
    attributes: [
      {
        label: "Action",
        value: item.deletedOn ? "Delete" : item.action || "Unknown",
      },
      { label: "P/N", value: item.partNumber },
      { label: "Ver.", value: item.version },
      { label: "Quantity", value: item.quantity },
    ],
    children: item.subParts?.length
      ? assembleRenderTree(item.subParts, linkableIds)
      : undefined,
  }));

export interface WorkOrderBillOfMaterialsProps {
  manager: ScrollNavManager;
  pageUrl: string;
  billOfMaterials: WorkOrderInstance["billOfMaterials"];
}

export const WorkOrderBillOfMaterials: React.FC<WorkOrderBillOfMaterialsProps> = (
  props
) => {
  const { manager, pageUrl, billOfMaterials } = props;

  const { linkableIds } = useLookup(
    "partInstance",
    "partInstanceId",
    "partInstanceId",
    billOfMaterials
  );

  const items = React.useMemo(() => {
    if (billOfMaterials && billOfMaterials.length > 0) {
      return assembleRenderTree(resolveSubParts(billOfMaterials), linkableIds);
    }
  }, [billOfMaterials, linkableIds]);

  return (
    <Hoverable component={MinimumButton}>
      <Block extraPadding>
        <MixedStateExpansionContextProvider>
          <Segment>
            <Sharable
              id={WorkOrderPageAnchors.BillOfMaterials}
              pageUrl={pageUrl}
              manager={manager}
              subject={<LightHeading>Bill of Materials</LightHeading>}
              disclaimer={ShareDisclaimer}
              actions={
                <MixedStateExpansionContext.Consumer>
                  {({ expandableNodeIds }) =>
                    expandableNodeIds.length > 0 && (
                      <ShareableActions>
                        <TreeViewExpansionButton
                          aria-controls="bom-tree-view"
                          labelSuffix="all bill of materials items"
                        />
                      </ShareableActions>
                    )
                  }
                </MixedStateExpansionContext.Consumer>
              }
            />
          </Segment>
          <Segment>
            {items && items.length > 0 ? (
              <DataTreeView id="bom-tree-view" items={items} />
            ) : (
              <Text>
                <em>No bill of materials items</em>
              </Text>
            )}
          </Segment>
        </MixedStateExpansionContextProvider>
      </Block>
    </Hoverable>
  );
};
