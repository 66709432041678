import {
  Block,
  Segment,
  SubtextHeading,
  Subheading,
  SegmentSpacing,
  Row,
  Column,
  ParagraphFontSize,
  Space,
  QuarterSpace,
} from "@blueorigin/blue-branding-kit";
import React from "react";
import { Review, PartInstance, DisplayNames } from "../../../models/generated";
import { Button } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Routes } from "../../../routes";
import { Assets } from "../../../assets";
import styled from "styled-components";

const ModifiedSubheading = styled(Subheading as any)`
  font-size: ${ParagraphFontSize};
`;

const ModifiedSubtextHeading = styled(SubtextHeading as any)`
  font-size: 2rem;
`;

const ImageWrap = styled.div`
  padding-right: ${Space};

  img {
    height: 8.1rem;
    width: 3.875rem;
  }
`;

const ButtonWrap = styled.div`
  padding-top: ${QuarterSpace};
`;

export interface ReviewItemType {
  review: Review;
  partInstance: PartInstance;
  error: Boolean;
}
export interface ReviewItemProps extends RouteComponentProps {
  reviewItem: ReviewItemType;
}

export const formatDescriptor = (
  serialNumber: string | null,
  lotNumber: string | null
) => {
  if (serialNumber) {
    return `Serial #${serialNumber}`;
  }
  if (lotNumber) {
    return `Lot #${lotNumber}`;
  }
  return "Pedigree";
};

export const ReviewItemComponent = (props: ReviewItemProps) => {
  const { type } = props.reviewItem.review;
  const {
    serialNumber,
    lotNumber,
    partNumber,
    partInstanceId,
  } = props.reviewItem.partInstance;
  const onClick = () => {
    props.history.push(Routes.partInstance(partInstanceId));
  };

  return (
    <Block extraPadding>
      <Row>
        <Column>
          <ImageWrap>
            <img alt="BE-4 Engine" src={Assets.BE4Small} />
          </ImageWrap>
        </Column>
        <Column>
          <Segment>
            <ModifiedSubheading>{DisplayNames[type]}</ModifiedSubheading>
          </Segment>
          <Segment spacing={SegmentSpacing.TINY}>
            <ModifiedSubtextHeading>
              {formatDescriptor(serialNumber, lotNumber)}
            </ModifiedSubtextHeading>
          </Segment>
          <Segment spacing={SegmentSpacing.LESS}>P/N {partNumber}</Segment>
          <Segment spacing={SegmentSpacing.LESS}>
            <ButtonWrap>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onClick}
              >
                View Pedigree
              </Button>
            </ButtonWrap>
          </Segment>
        </Column>
      </Row>
    </Block>
  );
};

export const ReviewItem = withRouter(ReviewItemComponent);
