import { Segment, theme, SegmentSpacing } from "@blueorigin/blue-branding-kit";
import React from "react";
import styled from "styled-components";
import { JulesContent } from "../../layout/jules-content";
import { Spacer } from "../../layout/spacer";

const H4 = styled.h4`
  font-size: ${theme("Typography.ParagraphFontSize")};
  margin: 0;
`;
const HR = styled.hr`
  border: 0.5px solid ${theme("Colors.LightGray")};
`;

export interface WorkOrderSkipConditionsProps {
  skippingCriteria: string[];
  isOperation?: boolean;
}

export const WorkOrderSkipConditions = (
  props: WorkOrderSkipConditionsProps
) => {
  const { skippingCriteria, isOperation } = props;
  return (
    <>
      <Segment>
        <H4>
          <Spacer>
            <span>Skip Conditions</span>
          </Spacer>
        </H4>
      </Segment>

      {skippingCriteria.map((criteria: string) => (
        <Segment spacing={SegmentSpacing.LESS}>
          {isOperation && <HR />}
          <JulesContent content={criteria} />
        </Segment>
      ))}
    </>
  );
};
