import * as React from "react";
import styled, { StyledComponent } from "styled-components";

const InteractionContainer = styled.div<HoverableProps>`
  ${({ component }) => component} {
    opacity: 0;
    transition: opacity 0.1s ease;
  }
  ${({ component }) => component}:focus,
  &.focusWithin
    ${({ component }) => component},
    &:focus-within
    ${({ component }) => component},
    &:hover
    ${({ component }) => component} {
    opacity: 1;
  }
`;
InteractionContainer.displayName = "InteractionContainer";

export interface HoverableProps {
  component: string | StyledComponent<any, any, any, any>;
}

export const Hoverable: React.FC<HoverableProps> = (props) => {
  const { component, children } = props;
  // "polyfill" for focusWithin (IE11)
  const [focusWithin, setFocusWithin] = React.useState(false);
  const handleFocus = () => setFocusWithin(true);
  const handleBlur = () => setFocusWithin(false);

  return (
    <InteractionContainer
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={focusWithin ? "focusWithin" : undefined}
      component={component}
    >
      {children}
    </InteractionContainer>
  );
};
