import { useState, useEffect } from "react";

/**
 * Debounce hook
 * @param value {object}
 * @param delay {number}
 * @returns debouncedValue {object}
 */
export default function useDebounce(
  value: { part: string; filter: string },
  delay: number
) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Return a cleanup function that will be called every time useEffect is re-called. useEffect will only be re-called
    // if value changes which prevents the debouncedValue from changing if value is changed within the delay period.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
