import styled from "styled-components";
import { Spacer } from "./spacer";

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled(Spacer).attrs({
  as: "li", // Make it a <li> tag
  role: "listitem", // Accessibility support for a list styled with `list-style: none`
})`
  padding: 0.5em 0;
  list-style: none;
`;
