import { Segment, Icon, theme, DateTime } from "@blueorigin/blue-branding-kit";
import React from "react";
import styled from "styled-components";
import {
  NonconformanceSignOff,
  WorkOrderSignOff,
} from "../../models/generated";
import moment from "moment";
import _ from "lodash";
import { Spacer } from "./spacer";

export const formatRole = (role: string) => {
  // Overrides for invalid role titles
  switch (role.toLowerCase()) {
    case "sdat": {
      return "Quality Engineer";
    }
    default: {
      // Format, e.g. mechanical engineer => Mechanical Engineer
      return _.startCase(_.camelCase(role));
    }
  }
};

const RedIcon = styled(Icon)`
  color: ${theme("Colors.PrimaryRed")};
`;
const GreenIcon = styled(Icon)`
  color: ${theme("Colors.PrimaryGreen")};
`;

export interface SignOffProps {
  signOff: WorkOrderSignOff | NonconformanceSignOff;
}

export const SignOff = (props: SignOffProps) => {
  const { signOff } = props;
  const { role, status } = signOff;
  const signed = status === "Signed";
  const IconComp = signed ? GreenIcon : RedIcon;
  const timestamp = "date" in signOff ? signOff.date : signOff.timestamp;

  return (
    <Segment>
      <Spacer>
        <IconComp
          label={signed ? "Checked" : "Unchecked"}
          name={signed ? "fas fa-check-circle" : "far fa-circle"}
        />
        <span>Sign-off</span>
        {role && <strong>{formatRole(role)}</strong>}
        {timestamp && <DateTime when={moment.unix(timestamp)} />}
        {!signed && (
          <em>
            <strong>Not signed</strong>
          </em>
        )}
      </Spacer>
    </Segment>
  );
};
