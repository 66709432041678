import { gql } from "@apollo/client";

export const BreadcrumbsPartInstanceFragment = gql`
  fragment partInstance on Query {
    partInstance(partInstanceId: $partInstanceId) {
      partInstanceId
      ancestry {
        partInstanceId
        partName
      }
    }
  }
`;

export const makeConditionalLookup = (
  queryName: string,
  queryField: string,
  ids?: string[] | null
) => {
  return gql`
    query Q {
      ${
        ids && ids.length > 0
          ? ids
              .map(
                (id) => `
                  lookup${id.replace(
                    /-/g,
                    ""
                  )}: ${queryName}(${queryField}: "${id}") {
                    ${queryField}
                  }`
              )
              .join()
          : "noop"
      }
    }
  `;
};
