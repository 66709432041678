import { HeaderAttributeProps as HeaderAttribute } from "@blueorigin/blue-branding-kit/components/header/attribute";
import { HeaderBreadcrumb } from "@blueorigin/blue-branding-kit";

export enum HydrationStatus {
  Empty, // No content has been loaded yet this session
  Loading, // Content is re-loading
  Filled, // Content is available
}

export interface LayoutState {
  hydration: HydrationStatus;
  title: string | null;
  attributes: HeaderAttribute[];
  breadcrumbs: HeaderBreadcrumb[];
}
