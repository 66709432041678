import React, { useEffect } from "react";
import { Column, Block, Row, Text } from "@blueorigin/blue-branding-kit";
import { SearchInput } from "./search-input";
import { FilterInput } from "./filter-input";
import { FilterButton } from "./filter-button";
import { ClearButton } from "./clear-button";
import { SearchResults } from "./search-results";
import { ClickAwayListener } from "@material-ui/core";
import styled from "styled-components";
import { gql, useLazyQuery } from "@apollo/client";
import useDebounce from "../../../hooks/use-debounce";

export const PartInstanceSearchQuery = gql`
  query partInstanceSearch($queryInput: QueryInput!) {
    partInstanceSearch(queryInput: $queryInput) {
      partInstanceId
      lotNumber
      partName
      partNumber
      serialNumber
    }
  }
`;

const SearchBlock = styled(Block)`
  position: relative;
`;

export interface SearchProperties {
  partName: string;
  lotNumber: string | null;
  partInstanceId: string;
  partNumber: string;
  serialNumber: string | null;
}

export const SearchBar = () => {
  // handles initial search input focus
  const [enabled, setEnabled] = React.useState(false);
  // handles popover open state
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  //handles Hint visible state vs. empty return
  const [hint, setHint] = React.useState(true);
  // handles appearance of rest of searchbar elements
  const [elementVisible, setElementVisibility] = React.useState(false);
  // handles appearance of filterbutton vs filter label
  const [enterButton, setEnterButton] = React.useState(true);
  // handles content/value of search inputs
  const [searchInput, setSearchInput] = React.useState({
    part: "",
    filter: "",
  });
  // sets focus on search input
  const [inputFocus, setInputFocus] = React.useState(false);
  // handles search results
  const [results, setResults] = React.useState<SearchProperties[]>([]);
  // handles search entry debounce
  const debouncedSearchTerm = useDebounce(searchInput, 1000);

  /**
   * Handles initial focus on Search Bar
   * @param enable {boolean}
   */
  const setToOpen = (enable: boolean) => {
    setEnabled(enable);
    setPopoverOpen(enable);
  };

  /**
   * Handles display of filter input label on filter input focus or
   * click of enter button
   */
  const handleLabelDisplay = () => {
    setEnterButton(false);
  };

  /**
   * Handles clear button. Resets all inputs, empties search results, and returns search bar to
   * initial enabled state
   */
  const onClearClick = () => {
    setSearchInput({
      ...searchInput,
      part: "",
      filter: "",
    });
    setResults([]);
    setInputFocus(true);
    setHint(true);
    setEnterButton(true);
    setElementVisibility(false);
  };

  /**
   * Handles click event outside searchbar and popover, and return searchbar to inhibited state
   * @returns if search bar is disabled/inactive
   */
  const onClickAway = () => {
    if (enabled === false) {
      return;
    }
    setInputFocus(false);
    setSearchInput({
      ...searchInput,
      part: "",
      filter: "",
    });
    setResults([]);
    setPopoverOpen(false);
    setEnabled(false);
    setHint(true);
    setEnterButton(true);
    setElementVisibility(false);
  };

  /**
   * Handles search input value change
   * @param value {string}
   * @param name {string}
   */
  const handleSearchChange = (value: string, name: string) => {
    setSearchInput({
      ...searchInput,
      [name]: value,
    });
    if (value.length > 2 && !elementVisible) {
      setElementVisibility(true);
    }
  };

  /**
   * Search query hook
   */
  const [executeSearch, { loading, data, error }] = useLazyQuery(
    PartInstanceSearchQuery
  );

  useEffect(() => {
    if (data) {
      setResults(
        Object.values(data.partInstanceSearch)
          .filter((value: any) => value != null)
          .map((value: any) => value)
      );
    }
  }, [data]);

  useEffect(() => {
    if (debouncedSearchTerm.part && debouncedSearchTerm.part.length > 2) {
      executeSearch({
        variables: {
          queryInput: {
            partNumber: debouncedSearchTerm.part,
            serialOrLotNumber: debouncedSearchTerm.filter,
          },
        },
      });
      setHint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div role="searchbox">
      <SearchBlock>
        <ClickAwayListener onClickAway={onClickAway}>
          <div>
            <Row>
              <Column
                width="30%"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SearchInput
                  open={enabled}
                  onOpenChange={setToOpen}
                  partValue={searchInput.part}
                  onValueChange={handleSearchChange}
                  setFocus={inputFocus}
                />
              </Column>
              <Column
                width="15%"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {enterButton && elementVisible && (
                  <FilterButton buttonClick={handleLabelDisplay} />
                )}
                {!enterButton && elementVisible && (
                  <Text>
                    <strong>Lot or serial number:</strong>
                  </Text>
                )}
              </Column>
              <Column width="20%" style={{ display: "flex" }}>
                {elementVisible && (
                  <FilterInput
                    setFocus={!enterButton}
                    partValue={searchInput.filter}
                    onValueChange={handleSearchChange}
                    focusOnInput={handleLabelDisplay}
                  />
                )}
              </Column>
              <Column width="35%" style={{ display: "flex" }}>
                {elementVisible && <ClearButton buttonClick={onClearClick} />}
              </Column>
            </Row>
            <SearchResults
              hint={hint}
              error={Boolean(error)}
              loading={loading}
              results={results}
              open={popoverOpen}
            />
          </div>
        </ClickAwayListener>
      </SearchBlock>
    </div>
  );
};
