import {
  Block,
  ScrollNavManager,
  Segment,
  SharableLightHeading,
  Subheading,
  Text,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import styled from "styled-components";
import { ShareDisclaimer } from "../../../constants";
import { Nonconformance } from "../../../models/generated";
import { JulesContent } from "../../layout/jules-content";
import { NonconformancePageAnchors } from "./nonconformance-page";

const H3 = styled(Subheading)`
  text-transform: initial;
  letter-spacing: initial;
`;

export interface NonconformanceCauseAssessmentProps {
  manager: ScrollNavManager;
  pageUrl: string;
  causeAssessment: Nonconformance["causeAssessment"];
}

export class NonconformanceCauseAssessment extends React.Component<NonconformanceCauseAssessmentProps> {
  public render() {
    const { manager, pageUrl, causeAssessment } = this.props;

    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={NonconformancePageAnchors.CauseAssessment}
            pageUrl={pageUrl}
            manager={manager}
            text="Cause Assessment"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        {causeAssessment &&
        (causeAssessment.cause || causeAssessment.supportingEvidence) ? (
          <>
            {causeAssessment.cause && (
              <>
                <Segment>
                  <H3>What caused this non-conformance?</H3>
                </Segment>
                <Segment>
                  <JulesContent content={causeAssessment.cause} />
                </Segment>
              </>
            )}
            {causeAssessment.supportingEvidence && (
              <>
                <Segment>
                  <H3>Supporting evidence</H3>
                </Segment>
                <Segment>
                  <JulesContent content={causeAssessment.supportingEvidence} />
                </Segment>
              </>
            )}
          </>
        ) : (
          <Segment>
            <Text>
              <em>No cause assessment</em>
            </Text>
          </Segment>
        )}
      </Block>
    );
  }
}
