import { Block, Error } from "@blueorigin/blue-branding-kit";
import { PageLoading } from "@blueorigin/blue-branding-kit/components/loading/page-loading";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { File, ReportType, PartInstance } from "../../../models/generated";
import { FileDownload } from "./file-download";
import { FileEmbed } from "./file-embed";
import {
  setFilledAction,
  LayoutFilledActionPayload,
} from "../../../redux/layout/layout-actions";
import { setDisplayAction } from "../../../redux/display/display-actions";
import { DisplayState } from "../../../redux/display/display-state";
import { ActionCreator } from "typescript-fsa";
import { configureBreadcrumbs } from "../page-header-manager";
import { gql, QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import {
  MIME_TYPE_MAP,
  FileType,
} from "@blueorigin/blue-branding-kit/utils/files";
import { FileToolbar } from "./file-toolbar";
import { BreadcrumbsPartInstanceFragment } from "../../../shared-queries";
import { DisplayComponent } from "../../../constants";

export const FileReportFragment = gql`
  fragment fileReport on Query {
    fileReport(reportId: $reportId) {
      reportId
      reportType
      mimeType
      fileName
      fileSizeBytes
      url
    }
  }
`;

export const FilePageWithPartQuery = gql`
  ${FileReportFragment}
  ${BreadcrumbsPartInstanceFragment}
  query Q($reportId: String!, $partInstanceId: String!) {
    ...fileReport
    ...partInstance
  }
`;

export const FilePageQuery = gql`
  ${FileReportFragment}
  query Q($reportId: String!) {
    ...fileReport
  }
`;

const ERROR_TITLE = "File Not Found";

const reportTypeFormatted = {
  [ReportType.WaiverDeviation]: "Waiver / Deviation",
  [ReportType.EngineeringChange]: "Engineering Change",
  [ReportType.WorkOrderInstance]: "Work Order",
  [ReportType.InstanceAttachment]: "Instance Attachment",
  [ReportType.NonConformance]: "Non-Conformance",
};

type KnownMimeType = keyof typeof MIME_TYPE_MAP;

export interface FileComponentProps {
  file: File;
  fileType: FileType;
}

export interface FilePageProps
  extends RouteComponentProps<{ reportId: string; partInstanceId?: string }> {
  setFilledAction: (payload: LayoutFilledActionPayload) => void;
  setDisplayAction: ActionCreator<DisplayState>;
}

export class FilePageComponent extends React.Component<FilePageProps> {
  public componentDidMount() {
    this.configureSearchBar();
  }

  public render() {
    const { reportId, partInstanceId } = this.props.match.params;
    return (
      <Query
        query={partInstanceId ? FilePageWithPartQuery : FilePageQuery}
        variables={partInstanceId ? { reportId, partInstanceId } : { reportId }}
      >
        {this.renderQuery}
      </Query>
    );
  }

  public renderQuery = ({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<{
    fileReport: File;
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">;
  }>) => {
    if (loading) {
      return <PageLoading />;
    }

    if (error || !data?.fileReport) {
      this.configureHeader();
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load file page
        </Error>
      );
    }

    const { fileReport, partInstance } = data;
    this.configureHeader(fileReport, partInstance);

    // Find `FileType` from `mimeType`, default to `OTHER` if `mimeType` is not in the map
    const fileType: FileType =
      MIME_TYPE_MAP[fileReport.mimeType as KnownMimeType] ?? FileType.OTHER;

    const FileComponent =
      fileType === FileType.ADOBE_PDF ? FileEmbed : FileDownload;

    return (
      <Block border={false}>
        <FileToolbar file={fileReport} fileType={fileType} />
        <FileComponent file={fileReport} fileType={fileType} />
      </Block>
    );
  };

  public configureSearchBar = () => {
    this.props.setDisplayAction({
      payload: DisplayComponent.searchBar,
    });
  };

  public configureHeader = (
    fileReport?: File,
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">
  ) => {
    const attributes = fileReport
      ? fileReport.reportType
        ? [
            {
              label: reportTypeFormatted[fileReport.reportType],
              value: "",
            },
          ]
        : undefined
      : undefined;
    this.props.setFilledAction({
      title: fileReport ? fileReport.fileName : ERROR_TITLE,
      attributes,
      breadcrumbs: fileReport
        ? configureBreadcrumbs(partInstance?.ancestry, false)
        : configureBreadcrumbs(),
    });
  };
}

export const FilePage = connect(null, { setFilledAction, setDisplayAction })(
  withRouter<any, any>(FilePageComponent)
);
