import React from "react";
import { Button } from "@material-ui/core";
import { theme } from "@blueorigin/blue-branding-kit";
import styled from "styled-components";

const StyledButton = styled(Button)`
  text-transform: none;
  background-color: ${theme("Colors.LightGray")};
  border-radius: 30px;
  padding: 0 6px;
`;

export interface FilterButtonProps {
  buttonClick: () => void;
}

export const FilterButton: React.FC<FilterButtonProps> = (props) => {
  const { buttonClick } = props;

  /**
   * Handle enter button click
   */
  const onClick = () => {
    buttonClick();
  };

  return (
    <div>
      <StyledButton tabIndex="-1" onClick={onClick}>
        <strong>Enter</strong>
      </StyledButton>
    </div>
  );
};
