import React from "react";
import { Blue } from "@blueorigin/blue-branding-kit";
import { appConfig } from "./config";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Wrapper } from "./components/wrapper";
import { LoginCallback, OktaRoutes } from "@blueorigin/authentication-library";
import { BrowserRouter } from "react-router-dom";

export const App = () => {
  return (
    <Blue>
      <Provider store={store}>
        <BrowserRouter>
          <OktaRoutes
            authUri={appConfig.authUri}
            config={appConfig.okta}
            wrapper={Wrapper}
            callback={LoginCallback}
          />
        </BrowserRouter>
      </Provider>
    </Blue>
  );
};
