import {
  Block,
  MinimumButton,
  Column,
  ColumnWidth,
  Container,
  Row,
  ScrollNav,
  ScrollNavItem,
  ScrollNavManager,
  Segment,
  Sticky,
  Error,
} from "@blueorigin/blue-branding-kit";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { LightHeading } from "@blueorigin/blue-branding-kit/components/text/light-heading";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { appConfig } from "../../../config";
import {
  Nonconformance,
  NonconformanceStatus,
  PartInstance,
} from "../../../models/generated";
import { ApolloError } from "@apollo/client";
import { Routes } from "../../../routes";
import {
  setFilledAction,
  LayoutFilledActionPayload,
} from "../../../redux/layout/layout-actions";
import { setDisplayAction } from "../../../redux/display/display-actions";
import { DisplayState } from "../../../redux/display/display-state";
import { ActionCreator } from "typescript-fsa";
import { configureBreadcrumbs } from "../page-header-manager";
import { NonconformanceSummary } from "./nonconformance-summary";
import { NonconformanceDefectiveParts } from "./nonconformance-defective-parts";
import { NonconformanceProblemSources } from "./nonconformance-problem-sources";
import { NonconformanceCauseAssessment } from "./nonconformance-cause-assessment";
import { NonconformanceDispositions } from "./nonconformance-dispositions";
import { NonconformanceContainment } from "./nonconformance-containment";
import { gql, QueryResult } from "@apollo/client";
import { BreadcrumbsPartInstanceFragment } from "../../../shared-queries";
import { Query } from "@apollo/client/react/components";
import { PageLoading } from "@blueorigin/blue-branding-kit/components/loading/page-loading";
import _ from "lodash";
import { DisplayComponent } from "../../../constants";
import { ExportedNC } from "../export-util";

export const NonconformanceFragment = gql`
  fragment nonconformance on Query {
    nonconformance(reportId: $reportId) {
      reportId
      displayId
      rejectCode
      rejectCategory
      summary {
        initialCondition
        shouldBeCondition
        whereFound
        status
        createdOn
        closedOn
        resolveBy
        attachments {
          mimeType
          fileName
          fileSizeBytes
          url
        }
      }
      defectiveParts {
        partInstanceId
        lotNumber
        partName
        partNumber
        serialNumber
      }
      problemSources {
        problemSourceId
        type
        name
      }
      causeAssessment {
        supportingEvidence
        cause
      }
      containment {
        whereFound
        containmentRequired
      }
      dispositions {
        dispositionId
        displayId
        type
        status
        engineeringAnalysis
        rationale
        deviations
        notes
        nonconformanceWorkOrders {
          id
          displayId
        }
        signOffs {
          status
          role
          date
        }
      }
    }
  }
`;

export const NonconformancePageWithPartQuery = gql`
  ${NonconformanceFragment}
  ${BreadcrumbsPartInstanceFragment}
  query Q($reportId: String!, $partInstanceId: String!) {
    ...nonconformance
    ...partInstance
  }
`;

export const NonconformancePageQuery = gql`
  ${NonconformanceFragment}
  query Q($reportId: String!) {
    ...nonconformance
  }
`;

const ERROR_TITLE_NOT_FOUND = "Non-Conformance Not Found";
const ERROR_TITLE_ENUM_DNE = "Cannot Display Non-Conformance";

export const NonconformancePageAnchors = {
  Summary: "summary",
  DefectiveParts: "defective-parts",
  ProblemSources: "problem-sources",
  CauseAssessment: "cause-assessment",
  Containment: "containment",
  Dispositions: "dispositions",
};

export const formatStatus = (status: NonconformanceStatus | null) =>
  _.startCase(_.camelCase(status || "Unknown"));

export const createTitle = (nonconformance: Nonconformance) => {
  let title = `NC-${nonconformance.displayId}: `;
  if (!nonconformance.rejectCategory && !nonconformance.rejectCode) {
    title += "Unknown Rejection";
  } else {
    title += `${nonconformance.rejectCategory ?? "Unknown Category"}${
      nonconformance.rejectCode ? ` (${nonconformance.rejectCode})` : ""
    }`;
  }
  return title;
};

export interface NonconformancePageProps
  extends RouteComponentProps<{ reportId: string; partInstanceId?: string }> {
  setFilledAction: (payload: LayoutFilledActionPayload) => void;
  setDisplayAction: ActionCreator<DisplayState>;
}

export class NonconformancePageComponent extends React.Component<NonconformancePageProps> {
  public manager: ScrollNavManager;

  public constructor(props: NonconformancePageProps) {
    super(props);
    this.manager = new ScrollNavManager({ offset: -100 });
  }

  public componentDidMount() {
    this.configureSearchBar();
  }

  public render() {
    const { reportId, partInstanceId } = this.props.match.params;
    return (
      <Query
        query={
          partInstanceId
            ? NonconformancePageWithPartQuery
            : NonconformancePageQuery
        }
        variables={partInstanceId ? { reportId, partInstanceId } : { reportId }}
      >
        {this.renderQuery}
      </Query>
    );
  }

  public renderQuery = ({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<{
    nonconformance: Nonconformance;
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">;
  }>) => {
    if (loading) {
      return <PageLoading />;
    }

    if (error || !data?.nonconformance) {
      this.configureHeaderWithError(error);
      if (error?.message.includes("cannot represent value")) {
        return (
          <Error data={error} tryAgain={refetch}>
            Cannot display non-conformance
          </Error>
        );
      }
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load non-conformance page
        </Error>
      );
    }

    const { nonconformance, partInstance } = data;
    this.configureHeader(nonconformance, partInstance);

    const pageUrl = `${appConfig.appUri}${Routes.nonconformance(
      nonconformance.reportId,
      partInstance?.partInstanceId
    )}`;
    const { manager } = this;

    return (
      <Container>
        <Row>
          <Column width={ColumnWidth.fillWidth}>
            <NonconformanceSummary
              manager={manager}
              pageUrl={pageUrl}
              summary={nonconformance.summary}
              dispositions={nonconformance.dispositions}
            />
            <NonconformanceDefectiveParts
              manager={manager}
              pageUrl={pageUrl}
              defectiveParts={nonconformance.defectiveParts}
            />
            <NonconformanceProblemSources
              manager={manager}
              pageUrl={pageUrl}
              problemSources={nonconformance.problemSources}
            />
            <NonconformanceCauseAssessment
              manager={manager}
              pageUrl={pageUrl}
              causeAssessment={nonconformance.causeAssessment}
            />
            <NonconformanceContainment
              manager={manager}
              pageUrl={pageUrl}
              containment={nonconformance.containment}
            />
            <NonconformanceDispositions
              manager={manager}
              pageUrl={pageUrl}
              dispositions={nonconformance.dispositions}
            />
          </Column>
          <Column width="300px">
            <Sticky top="6rem">
              <Block extraPadding border={false}>
                <ScrollNav manager={manager}>
                  <Segment>
                    <LightHeading>Navigation</LightHeading>
                  </Segment>
                  <Segment>
                    <ScrollNavItem
                      id={NonconformancePageAnchors.Summary}
                      manager={manager}
                    >
                      Summary
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={NonconformancePageAnchors.DefectiveParts}
                      manager={manager}
                    >
                      Defective Parts
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={NonconformancePageAnchors.ProblemSources}
                      manager={manager}
                    >
                      Problem Sources
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={NonconformancePageAnchors.CauseAssessment}
                      manager={manager}
                    >
                      Cause Assessment
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={NonconformancePageAnchors.Containment}
                      manager={manager}
                    >
                      Containment
                    </ScrollNavItem>
                    <ScrollNavItem
                      id={NonconformancePageAnchors.Dispositions}
                      manager={manager}
                    >
                      Dispositions
                    </ScrollNavItem>

                    <PDFDownloadLink
                      style={{ textDecoration: "none" }}
                      document={
                        <ExportedNC nonconformance={data.nonconformance} />
                      }
                      fileName={data.nonconformance.reportId}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading..." : "Export all"
                      }
                      <MinimumButton
                        color="primary"
                        variant="contained"
                        style={{ marginTop: "10px" }}
                      >
                        Export all
                      </MinimumButton>
                    </PDFDownloadLink>
                  </Segment>
                </ScrollNav>
              </Block>
            </Sticky>
          </Column>
        </Row>
      </Container>
    );
  };

  public configureSearchBar = () => {
    this.props.setDisplayAction({
      payload: DisplayComponent.searchBar,
    });
  };

  public configureHeader = (
    nonconformance: Nonconformance,
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">
  ) => {
    this.props.setFilledAction({
      title: createTitle(nonconformance),
      attributes: [
        {
          label: "Status:",
          value: formatStatus(nonconformance.summary.status),
        },
      ],
      breadcrumbs: configureBreadcrumbs(partInstance?.ancestry, false),
    });
  };

  public configureHeaderWithError = (error?: ApolloError) => {
    this.props.setFilledAction({
      title: error?.message.includes("cannot represent value")
        ? ERROR_TITLE_ENUM_DNE
        : ERROR_TITLE_NOT_FOUND,
      breadcrumbs: configureBreadcrumbs(),
    });
  };
}

export const NonconformancePage = connect(null, {
  setFilledAction,
  setDisplayAction,
})(withRouter<any, any>(NonconformancePageComponent));
