import actionCreatorFactory from "typescript-fsa";
import { HeaderAttributeProps as HeaderAttribute } from "@blueorigin/blue-branding-kit/components/header/attribute";
import { HeaderBreadcrumb } from "@blueorigin/blue-branding-kit";

const actionCreator = actionCreatorFactory();

export interface LayoutFilledActionPayload {
  title: string;
  attributes?: HeaderAttribute[];
  breadcrumbs?: HeaderBreadcrumb[];
}

export const setLoadingAction = actionCreator<{}>("LAYOUT_SET_LOADING");
export const setEmptyAction = actionCreator<{}>("LAYOUT_SET_EMPTY");
export const setFilledAction = actionCreator<LayoutFilledActionPayload>(
  "LAYOUT_SET_FILLED"
);
