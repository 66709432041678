import {
  Block,
  ScrollNavManager,
  Segment,
} from "@blueorigin/blue-branding-kit";
import { SharableLightHeading } from "@blueorigin/blue-branding-kit/components/navigation/scrollnav/share/sharable-light-heading";
import * as React from "react";
import { ChangePageAnchors } from "./change-page";
import { ChangeImpact as ChangeImpactModel } from "../../../models/generated";
import { KeyValue, KeyValueWeight } from "../../layout/key-value";
import { formatChangeClassification } from "./change-classification";
import { ITSContent } from "../../layout/its-content";
import { ShareDisclaimer } from "../../../constants";

export interface ChangeImpactProps {
  manager: ScrollNavManager;
  pageUrl: string;
  impact: ChangeImpactModel;
  changeClassification: string;
}

export class ChangeImpact extends React.Component<ChangeImpactProps> {
  public render() {
    const { manager, pageUrl, impact, changeClassification } = this.props;
    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={ChangePageAnchors.Impact}
            pageUrl={pageUrl}
            manager={manager}
            text="Impact"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        <KeyValue
          label="Type"
          value={formatChangeClassification(changeClassification)}
          weight={KeyValueWeight.Light}
        />
        {impact.subsystem && (
          <KeyValue
            label="Subsystem"
            value={impact.subsystem}
            weight={KeyValueWeight.Light}
          />
        )}
        {impact.overallSystemImpact && (
          <KeyValue
            label="Overall System Impact"
            value={<ITSContent content={impact.overallSystemImpact} />}
          />
        )}
      </Block>
    );
  }
}
