import {
  Block,
  ScrollNavManager,
  Segment,
  Text,
  DateTime,
  PrimaryGreen,
  theme,
  Icon,
  FileThumb,
  SharableLightHeading,
  AnchorLinkUndecorated,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import { WorkOrderInstance, WorkOrderType } from "../../../models/generated";
import { WorkOrderPageAnchors } from "./work-order-page";
import { TimelineEdge } from "../../layout/timeline-edge";
import moment from "moment";
import styled from "styled-components";
import { JulesContent } from "../../layout/jules-content";
import { Spacer } from "../../layout/spacer";
import { StrongTime } from "../../layout/strong-time";

const Icons = styled.span`
  position: relative;
`;

const PartIcon = styled(Icon)`
  color: ${theme("Colors.PrimaryBlue")};
`;

const SecondaryIcon = styled(Icon)`
  position: absolute;
  bottom: -2px;
  right: -2px;
  height: 0.75rem;
  font-size: 0.5rem;
  width: 0.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("Colors.White")};
  background-color: ${theme("Colors.PrimaryGreen")};
`;

export const formatPrefixByType = (type: WorkOrderType | null) => {
  switch (type) {
    case WorkOrderType.Produce:
      return (
        <>
          <strong>Produce</strong> quantity{" "}
        </>
      );
    case WorkOrderType.Procedure:
    case WorkOrderType.Maintenance:
      return (
        <>
          <strong>{type}</strong> for quantity{" "}
        </>
      );
    case WorkOrderType.ReceivingInspection:
      return (
        <>
          <strong>Receiving inspection</strong> for quantity{" "}
        </>
      );
    default:
      return "Quantity ";
  }
};

export interface WorkOrderSummaryProps {
  manager: ScrollNavManager;
  pageUrl: string;
  summary: WorkOrderInstance["summary"];
  type: WorkOrderInstance["type"];
}

export class WorkOrderSummary extends React.Component<WorkOrderSummaryProps> {
  public render() {
    const { manager, pageUrl, summary, type } = this.props;

    return (
      <Block extraPadding>
        <Segment>
          <SharableLightHeading
            id={WorkOrderPageAnchors.Summary}
            pageUrl={pageUrl}
            manager={manager}
            text="Summary"
            disclaimer={ShareDisclaimer}
          />
        </Segment>
        {summary ? (
          <>
            <Segment>
              <Spacer>
                <Icons>
                  <PartIcon name="fas fa-cog" decorative />
                  {type === WorkOrderType.Produce && (
                    <SecondaryIcon name="fas fa-plus" decorative />
                  )}
                  {type === WorkOrderType.Maintenance && (
                    <SecondaryIcon name="fas fa-wrench" decorative />
                  )}
                </Icons>
                <span>
                  {formatPrefixByType(type)}
                  <strong>{summary.quantity}</strong>
                  {" of "}
                  <strong>{summary.partName}</strong>
                </span>
                <span>
                  P/N <strong>{summary.partNumber}</strong>
                </span>
                <span>
                  Ver. <strong>{summary.version}</strong>
                </span>
              </Spacer>
            </Segment>
            <Segment>
              <TimelineEdge
                label="Started"
                value={
                  <StrongTime>
                    <DateTime when={moment.unix(summary.startTimestamp)} />
                  </StrongTime>
                }
                color={PrimaryGreen}
                icon="fas fa-circle"
              />
            </Segment>
            {summary.completedTimestamp && (
              <Segment>
                <TimelineEdge
                  label="Completed"
                  value={
                    <StrongTime>
                      <DateTime
                        when={moment.unix(summary.completedTimestamp)}
                      />
                    </StrongTime>
                  }
                  color={PrimaryGreen}
                  icon="fas fa-check-circle"
                  track
                />
                {summary.lotNumbers && (
                  <strong>
                    {summary.lotNumbers
                      .map((lotNumber) => `Lot ${lotNumber}`)
                      .join(", ")}
                  </strong>
                )}
              </Segment>
            )}
            {summary.notes && (
              <Segment>
                <JulesContent content={summary.notes} />
              </Segment>
            )}
            {summary.attachments && summary.attachments.length > 0 && (
              <Segment>
                {summary.attachments.map(({ fileName, url }) => (
                  <AnchorLinkUndecorated
                    key={url}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FileThumb file={{ name: fileName, url }}></FileThumb>
                  </AnchorLinkUndecorated>
                ))}
              </Segment>
            )}
          </>
        ) : (
          <Segment>
            <Text>
              <em>No summary available</em>
            </Text>
          </Segment>
        )}
      </Block>
    );
  }
}
