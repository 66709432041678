import {
  Block,
  MixedStateExpansionContext,
  MixedStateExpansionContextProvider,
  MixedStateExpansionButton,
  LightHeading,
  MinimumButton,
  ScrollNavManager,
  Segment,
  Sharable,
  Text,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { ShareDisclaimer } from "../../../constants";
import { WorkOrderPageAnchors } from "./work-order-page";
import { OperationStatus, WorkOrderInstance } from "../../../models/generated";
import { WorkOrderOperation } from "./work-order-operation";
import { Hoverable } from "../../layout/hoverable";
import { ShareableActions } from "../../layout/shareable-actions";

export interface WorkOrderOperationsProps {
  manager: ScrollNavManager;
  pageUrl: string;
  operations: WorkOrderInstance["operations"];
}

export const WorkOrderOperationsInner = (props: WorkOrderOperationsProps) => {
  const { manager, pageUrl, operations } = props;

  const {
    setExpandableNodeIds,
    setExpandedNodeIds,
    expandableNodeIds,
  } = React.useContext(MixedStateExpansionContext);
  React.useEffect(() => {
    const nodeIds = operations?.map((operation) => operation.id) || [];
    const completedNodeIds =
      operations
        ?.filter(
          (operation) =>
            operation.status !== OperationStatus.Deleted &&
            operation.status !== OperationStatus.Skipped
        )
        .map((operation) => operation.id) || [];
    setExpandableNodeIds?.(nodeIds);
    setExpandedNodeIds?.(completedNodeIds);
  }, [setExpandedNodeIds, setExpandableNodeIds, operations]);

  const handleExpand = () => setExpandedNodeIds!(expandableNodeIds);
  const handleCollapse = () => setExpandedNodeIds!([]);
  const displayedOperations = operations?.filter(
    (operation) =>
      operation.status !== OperationStatus.Deleted &&
      operation.title !== "Deleted"
  );
  return (
    <>
      <Segment>
        <Sharable
          id={WorkOrderPageAnchors.Operations}
          pageUrl={pageUrl}
          manager={manager}
          disclaimer={ShareDisclaimer}
          subject={<LightHeading>Operations</LightHeading>}
          actions={
            <MixedStateExpansionContext.Consumer>
              {({ expandableNodeIds, canExpand, canCollapse }) =>
                expandableNodeIds.length > 0 && (
                  <ShareableActions>
                    <MixedStateExpansionButton
                      aria-controls="operations-items"
                      labelSuffix="all operations"
                      canExpand={canExpand}
                      canCollapse={canCollapse}
                      onCollapse={handleCollapse}
                      onExpand={handleExpand}
                    />
                  </ShareableActions>
                )
              }
            </MixedStateExpansionContext.Consumer>
          }
        />
      </Segment>
      <Segment>
        <div id="operations-items">
          {displayedOperations && displayedOperations?.length > 0 ? (
            displayedOperations.map((operation) => (
              <WorkOrderOperation
                key={operation.id}
                operation={operation}
                pageUrl={pageUrl}
                manager={manager}
              />
            ))
          ) : (
            <Text>
              <em>No operations</em>
            </Text>
          )}
        </div>
      </Segment>
    </>
  );
};

export const WorkOrderOperations = (props: WorkOrderOperationsProps) => {
  return (
    <Hoverable component={MinimumButton}>
      <Block extraPadding border={false}>
        <MixedStateExpansionContextProvider>
          <WorkOrderOperationsInner {...props} />
        </MixedStateExpansionContextProvider>
      </Block>
    </Hoverable>
  );
};
