import React from "react";
import { Switch, Route } from "react-router-dom";
import { ReviewsPage } from "./reviews/reviews-page";
import { DetailsPage } from "./details/details-page";
import { ChangePage } from "./change/change-page";
import { FilePage } from "./file/file-page";
import { Routes } from "../../routes";
import { WorkOrderPage } from "./work-orders/work-order-page";
import { NonconformancePage } from "./nonconformance/nonconformance-page";

export const PageRouter = () => (
  <Switch>
    <Route
      path={Routes.engineeringChange(":reportId")}
      component={ChangePage}
    />
    <Route
      path={Routes.engineeringChange(":reportId", ":partInstanceId")}
      component={ChangePage}
    />
    <Route path={Routes.file(":reportId")} component={FilePage} />
    <Route
      path={Routes.file(":reportId", ":partInstanceId")}
      component={FilePage}
    />
    <Route path={Routes.workOrder(":reportId")} component={WorkOrderPage} />
    <Route
      path={Routes.workOrder(":reportId", ":partInstanceId")}
      component={WorkOrderPage}
    />
    <Route
      path={Routes.nonconformance(":reportId")}
      component={NonconformancePage}
    />
    <Route
      path={Routes.nonconformance(":reportId", ":partInstanceId")}
      component={NonconformancePage}
    />
    <Route path={Routes.partInstance(":instanceId")} component={DetailsPage} />
    <Route path={Routes.myReviews} component={ReviewsPage} />
  </Switch>
);
