import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as Actions from "./user-actions";
import { UserState } from "./user-state";

const initialState: any = {
  isAuthenticated: false,
  groups: null,
  name: null,
  email: null,
};

const guestState: UserState = {
  isAuthenticated: false,
  groups: null,
  name: null,
  email: null,
};

export const UserReducer = reducerWithInitialState(initialState)
  .case(Actions.setAuthenticationAction, (state, userInfo: UserState) => ({
    ...state,
    groups: userInfo.groups,
    isAuthenticated: true,
    name: userInfo.name,
    email: userInfo.email,
  }))

  .case(Actions.clearAuthenticationAction, (state) => ({
    ...state,
    ...guestState,
  }))

  .build();
