import {
  Block,
  Segment,
  Heading,
  Text,
  Error,
  Subheading,
  Notice,
  Mood,
} from "@blueorigin/blue-branding-kit";
import { PageLoading } from "@blueorigin/blue-branding-kit/components/loading/page-loading";
import React from "react";
import { ReviewItem, ReviewItemType } from "./review-item";
import { Button } from "@material-ui/core";
import { Routes } from "../../../routes";
import { connect } from "react-redux";
import {
  setFilledAction,
  LayoutFilledActionPayload,
} from "../../../redux/layout/layout-actions";
import { setDisplayAction } from "../../../redux/display/display-actions";
import { DisplayState } from "../../../redux/display/display-state";
import { ActionCreator } from "typescript-fsa";
import { gql, QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";
import { DisplayComponent } from "../../../constants";

const REVIEWS_PAGE_TITLE = "My Reviews";
const REVIEW_ATTRIBUTES_VALUE = "Available";
const ERROR_TITLE = "Something went wrong";

export const ReviewsPageQuery = gql`
  {
    myReviews {
      review {
        reviewId
        partInstanceId
        title
        type
        createdTimestamp
      }
      partInstance {
        partInstanceId
        partName
        partNumber
        serialNumber
        lotNumber
        reviewId
      }
      error
    }
  }
`;

export interface ReviewsPageProps {
  setFilledAction: ActionCreator<LayoutFilledActionPayload>;
  setDisplayAction: ActionCreator<DisplayState>;
}

export class ReviewsPageComponent extends React.Component<ReviewsPageProps> {
  public onClick = () => {
    window.location.assign(Routes.contact);
  };

  public componentDidMount() {
    this.configureSearchBar();
  }

  public render() {
    return (
      <Query
        query={ReviewsPageQuery}
        onCompleted={(data: ReviewItemType[]) => this.onCompleted(data)}
      >
        {this.renderQuery}
      </Query>
    );
  }

  public renderQuery = ({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<{
    myReviews: ReviewItemType[];
  }>) => {
    if (loading) {
      return <PageLoading />;
    }
    if (error || !data) {
      this.configureHeaderWithError();
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load reviews page
        </Error>
      );
    }

    const { myReviews } = data;
    const hasError = Boolean(myReviews.find((rev) => rev.error));
    const filteredReviewers = myReviews.filter((rev) => !rev.error);

    return (
      <React.Fragment>
        {hasError && (
          <Block>
            <Notice mood={Mood.WARNING}>
              Some reviews are currently unavailable. Please contact Blue Origin
              for assistance.
            </Notice>
          </Block>
        )}
        {filteredReviewers && filteredReviewers.length ? (
          filteredReviewers.map((rev: ReviewItemType) => (
            <ReviewItem
              key={rev.partInstance.partInstanceId}
              reviewItem={rev}
            />
          ))
        ) : (
          <Block>
            <Segment>
              <Subheading>No Reviews</Subheading>
            </Segment>
            <Segment>No reviews are currently assigned.</Segment>
          </Block>
        )}
        <Block extraPadding>
          <Segment>
            <Heading>Contact &amp; Help</Heading>
          </Segment>
          <Segment>
            <Text>
              Need to contact us with questions about your engines, or need help
              using this website? Send a message -- we'll get back to you as
              soon as possible.
            </Text>
          </Segment>
          <Segment>
            <Button variant="contained" color="primary" onClick={this.onClick}>
              Contact Us
            </Button>
          </Segment>
        </Block>
      </React.Fragment>
    );
  };

  public configureSearchBar = () => {
    this.props.setDisplayAction({
      payload: DisplayComponent.searchBar,
    });
  };

  public onCompleted = (data: any) => {
    if (data && data.myReviews) {
      const { myReviews } = data;
      this.props.setFilledAction({
        title: REVIEWS_PAGE_TITLE,
        attributes: [
          {
            label: myReviews.length.toString(),
            value: REVIEW_ATTRIBUTES_VALUE,
            ReverseStyling: true,
          },
        ],
      });
    }
  };

  public configureHeaderWithError = () => {
    this.props.setFilledAction({
      title: ERROR_TITLE,
      breadcrumbs: [],
    });
  };
}

export const ReviewsPage = connect(null, {
  setFilledAction,
  setDisplayAction,
})(withRouter<any, any>(ReviewsPageComponent));
