interface AppConfig {
  apolloUri: string;
  authUri: string;
  homeUri: string;
  appUri: string;
  okta: typeof okta;
  telemetryId: number;
}

const okta = {
  clientId: "0oa4zh99k3k7WDbey357",
};

let config: AppConfig;
const env = process.env.REACT_APP_ENV;
if (env === "prod") {
  config = {
    apolloUri:
      "https://d027ec1ey3.execute-api.us-gov-west-1.amazonaws.com/prod/graphql",
    authUri: "https://auth.blueorigin.com",
    homeUri: "https://www.blueorigin.com",
    appUri: "https://pedigree.blueorigin.com",
    okta,
    telemetryId: 4,
  };
} else if (env === "stage") {
  config = {
    apolloUri:
      "https://h10een873m.execute-api.us-gov-west-1.amazonaws.com/stage/graphql",
    authUri: "https://gamma-auth.blueorigin.com",
    homeUri: "https://www.blueorigin.com",
    appUri: "https://gamma-pedigree.blueorigin.com",
    okta,
    telemetryId: 3,
  };
} else {
  config = {
    apolloUri:
      "https://6xgjxh9s95.execute-api.us-gov-west-1.amazonaws.com/dev/graphql",
    authUri: "https://beta-auth.blueorigin.com",
    homeUri: "https://www.blueorigin.com",
    appUri: "https://beta-pedigree.blueorigin.com",
    okta,
    telemetryId: 1,
  };
}

export const appConfig = config;
