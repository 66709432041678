import { RootStateOrAny } from "react-redux";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as Actions from "./display-actions";
import { DisplayState } from "./display-state";

export const initialState: any = {
  show_list: {},
};

export const DisplayReducer = reducerWithInitialState(initialState)
  .case(
    Actions.setDisplayAction,
    (state: RootStateOrAny, action: DisplayState) => {
      const show_list = { ...state.show_list, [action.payload]: true };

      return {
        ...state,
        show_list,
      };
    }
  )
  .case(
    Actions.setDisplayHide,
    (state: RootStateOrAny, action: DisplayState) => {
      const show_list = { ...state.show_list, [action.payload]: false };

      return {
        ...state,
        show_list,
      };
    }
  )
  .build();
