import {
  Column,
  FauxLink,
  Icon,
  Row,
  Segment,
  theme,
} from "@blueorigin/blue-branding-kit";
import styled from "styled-components";
import * as React from "react";
import {
  getNonconformanceDeviationLevel,
  MajorNonconformanceDeviation,
  MinorNonconformanceDeviation,
  NonconformanceDeviationLevel,
  SafetyCriticalNonconformanceDeviation,
} from "../../../models/generated";
import { JulesContent } from "../../layout/jules-content";

const MinorLevel = styled.div`
  > i {
    padding-right: ${theme("Dimensions.QuarterSpace")};
  }
  color: ${theme("Colors.Black")};
`;

const MajorLevel = styled.div`
  > i {
    padding-right: ${theme("Dimensions.QuarterSpace")};
  }
  color: ${theme("Colors.PrimaryOrange")};
`;

const SafetyCriticalLevel = styled.div`
  > i {
    padding-right: ${theme("Dimensions.QuarterSpace")};
  }
  color: ${theme("Colors.PrimaryRed")};
`;

const NonAffectedLevel = styled.div`
  > div {
    color: ${theme("Colors.Gray")};
  }
`;

const NonAffectedCheckmark = styled.div`
  > i {
    padding-right: ${theme("Dimensions.QuarterSpace")};
  }
  color: ${theme("Colors.PrimaryGreen")};
`;

export interface DeviationsProps {
  deviations: string[];
}

interface DeviationProps {
  deviation: string;
  isAffected: boolean;
}

export const Deviations = ({ deviations }: DeviationsProps) => {
  const [unaffectedDivisions, setUnaffectedDivisions] = React.useState<
    string[]
  >([]);
  const [isHidden, setIsHidden] = React.useState(true);

  React.useEffect(() => {
    let unaffected = {
      ...MajorNonconformanceDeviation,
      ...SafetyCriticalNonconformanceDeviation,
    };
    deviations.forEach((deviation) => {
      delete unaffected[deviation];
    });
    setUnaffectedDivisions(Object.keys(unaffected));
  }, [deviations]);
  return (
    <>
      <Segment>
        <JulesContent
          content={`${
            deviations.length === 1 &&
            deviations[0] === MinorNonconformanceDeviation.None
              ? "No"
              : "Some"
          } factors were adversely affected`}
        />
      </Segment>
      <Segment>
        {deviations.map((deviation) => (
          <Segment key={deviation}>
            <DeviationComponent deviation={deviation} isAffected={true} />
          </Segment>
        ))}
        <Segment>
          <Segment>
            <FauxLink onClick={() => setIsHidden(!isHidden)}>
              {isHidden ? "Show" : "Hide"} {unaffectedDivisions.length}{" "}
              unaffected factor(s)
            </FauxLink>
          </Segment>
          {!isHidden &&
            unaffectedDivisions.map((deviation) => (
              <Segment key={deviation}>
                <DeviationComponent deviation={deviation} isAffected={false} />
              </Segment>
            ))}
        </Segment>
      </Segment>
    </>
  );
};

export const DeviationComponent = ({
  deviation,
  isAffected,
}: DeviationProps) => {
  const level = getNonconformanceDeviationLevel(deviation);
  if (!level) return null;

  const getIconLevel = (level: string) => {
    if (level === NonconformanceDeviationLevel.SafetyCritical) {
      return (
        <SafetyCriticalLevel>
          <Icon label={level} name="fas fa-exclamation-circle" />
          <strong>{level}</strong>
        </SafetyCriticalLevel>
      );
    }

    if (level === NonconformanceDeviationLevel.Major) {
      return (
        <MajorLevel>
          <Icon label={level} name="fas fa-exclamation-triangle" />
          <strong>{level}</strong>
        </MajorLevel>
      );
    }
    return (
      <MinorLevel>
        <Icon label={level} name="fas fa-exclamation-triangle" />
        <strong>{level}</strong>
      </MinorLevel>
    );
  };

  const deviationString =
    MajorNonconformanceDeviation[deviation] ||
    SafetyCriticalNonconformanceDeviation[deviation];

  return (
    <Row>
      <Column width="20%">
        {!isAffected ? (
          <NonAffectedLevel>{getIconLevel(level)}</NonAffectedLevel>
        ) : (
          getIconLevel(level)
        )}
      </Column>
      <Column width="32%">
        <span>{deviationString}</span>
      </Column>
      {!isAffected && (
        <Column>
          <NonAffectedCheckmark>
            <Icon name="fas fa-check" />
            <strong>Not affected</strong>
          </NonAffectedCheckmark>
        </Column>
      )}
    </Row>
  );
};
