import {
  Segment,
  Text,
  SegmentSpacing,
  theme,
  AnchorLinkUndecorated,
  FileThumb,
} from "@blueorigin/blue-branding-kit";
import * as React from "react";
import { CloseOut } from "../../../models/generated";
import styled from "styled-components";

const H5 = styled.h5`
  font-size: ${theme("Typography.ParagraphFontSize")};
  margin: 0;
`;

export interface WorkOrderCloseOutProps {
  closeOut: CloseOut;
}

export const WorkOrderCloseOut = (props: WorkOrderCloseOutProps) => {
  const { title, data, unit, suffix, attachments } = props.closeOut;

  return (
    <React.Fragment>
      <Segment>
        <H5>{title}</H5>
      </Segment>
      <Segment spacing={SegmentSpacing.LESS}>
        {data && !data?.includes("urn") ? (
          <Text>
            {data} {unit ? unit : suffix}
          </Text>
        ) : data?.includes("urn") ? (
          <Text></Text>
        ) : (
          <Text>
            <em>No data to display</em>
          </Text>
        )}
        {attachments && attachments.length > 0 && (
          <>
            <Segment>
              {attachments.map(({ fileName, url }) => (
                <AnchorLinkUndecorated
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileThumb file={{ name: fileName, url }}></FileThumb>
                </AnchorLinkUndecorated>
              ))}
            </Segment>
          </>
        )}
      </Segment>
    </React.Fragment>
  );
};
