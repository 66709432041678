import React from "react";
import { InputBase, withStyles } from "@material-ui/core";

const StyledFilterInput = withStyles({
  input: {
    "&::placeholder": {
      opacity: 0.8,
    },
  },
})(InputBase);

export interface FilterInputProps {
  setFocus?: boolean;
  partValue: string;
  onValueChange: (value: string, name: string) => void;
  focusOnInput: () => void;
}

export const FilterInput: React.FC<FilterInputProps> = (props) => {
  const { setFocus, partValue, onValueChange, focusOnInput } = props;

  /**
   * Handle filter input change
   * @param event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onValueChange(event.target.value, event.target.name);
  };

  /**
   * Handles focus on filter input
   * @param event
   */
  const FilterInputFocus = (
    event: React.FocusEventHandler<HTMLInputElement>
  ) => {
    focusOnInput();
  };

  return (
    <StyledFilterInput
      autoComplete="off"
      fullWidth
      inputRef={(input) => input && setFocus && input.focus()}
      placeholder="Filter by lot or serial number"
      value={partValue}
      onChange={handleChange}
      name="filter"
      inputProps={{
        onFocus: (event: any) => FilterInputFocus(event),
        "aria-label": "serial number search",
        style: {
          textOverflow: "ellipsis",
          width: "100%",
        },
      }}
    />
  );
};
