import React from "react";
import { ActionRow, Icon, theme } from "@blueorigin/blue-branding-kit";
import { TableCell } from "@material-ui/core";
import styled from "styled-components";
import { Routes } from "../../../routes";

export const SearchTableCell = styled(TableCell)`
  font-size: ${theme("Typography.ParagraphFontSize")};
  border-bottom: 0;
  padding: 0.75rem;
  white-space: nowrap;
`;

export const BlueTableCell = styled(SearchTableCell)`
  color: ${theme("Colors.PrimaryBlue")};
  font-weight: bold;
`;

export const IconTableCell = styled(BlueTableCell)`
  padding-right: 0;
  padding-left: 1rem;
`;

export const PartTableCell = styled(SearchTableCell)`
  white-space: normal;
  width: 50%;
`;

interface SearchResultProps {
  partInstanceId: string;
  partName: string;
  partNumber: string;
  serialNumber: string | null;
  lotNumber: string | null;
}

/**
 * Handles row onClick event
 * @param partInstanceId {string}
 */
export function onAction(partInstanceId: string) {
  window.location.assign(Routes.partInstance(partInstanceId!));
  return true;
}

export const SearchResult = (props: SearchResultProps) => {
  const {
    partInstanceId,
    partName,
    partNumber,
    serialNumber,
    lotNumber,
  } = props;
  return (
    <ActionRow onAction={() => onAction(partInstanceId!)}>
      <IconTableCell>
        <Icon name="fa fa-2x fa-cog"></Icon>
      </IconTableCell>
      <BlueTableCell>PART INSTANCE</BlueTableCell>
      <BlueTableCell>{partNumber}</BlueTableCell>
      <PartTableCell>{partName}</PartTableCell>
      <SearchTableCell>
        {serialNumber && <strong>Serial #{serialNumber}</strong>}
      </SearchTableCell>
      <SearchTableCell width="100%">
        {lotNumber && <strong>Lot #{lotNumber}</strong>}
      </SearchTableCell>
    </ActionRow>
  );
};
