import * as React from "react";
import * as _ from "lodash";

export interface ITSContentProps {
  content: string;
}

export class ITSContent extends React.Component<ITSContentProps> {
  public render() {
    const { content } = this.props;
    return _.map(content.split("\n"), (line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }
}
