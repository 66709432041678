import { Icon, theme } from "@blueorigin/blue-branding-kit";
import * as React from "react";
import styled from "styled-components";

const Item = styled.div`
  display: inline-block;
  margin-right: ${theme("Dimensions.ThreeQuarterSpace")};
`;

const Track = styled.span`
  position: absolute;
  background-color: currentColor;
  top: -1.75rem;
  bottom: 0.5rem;
  width: 0.25rem;
  left: 0.375rem;
  z-index: -1;
`;

const Edge = styled.span<{ color: string }>`
  position: relative;
  color: ${({ color }) => color};
`;

const EdgeIcon = styled(Icon)`
  background-color: ${theme("Colors.White")};
  border-radius: 50%;
`;

export interface TimelineEdgeProps {
  label: string;
  value: React.ReactElement;
  icon: string;
  color: string;
  track?: boolean;
}

export class TimelineEdge extends React.Component<TimelineEdgeProps> {
  public render() {
    const { label, value, icon, color, track } = this.props;
    return (
      <React.Fragment>
        <Item>
          <Edge color={color}>
            {track && <Track />}
            <EdgeIcon name={icon} decorative={true} />
          </Edge>
        </Item>
        <Item>{label}</Item>
        <Item>{value}</Item>
      </React.Fragment>
    );
  }
}
