import React from "react";
import { SearchResult } from "./search-result";
import { SearchResultHint } from "./search-result-hint";
import { Paper, Popper, Table, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Block,
  Error,
  LightGray,
  Text,
  Subheading,
  theme,
} from "@blueorigin/blue-branding-kit";
import styled from "styled-components";
import { SearchProperties } from "./search-bar";

const SearchResultHeading = styled(Subheading)`
  font-size: ${theme("Typography.HeadingFontSize")};
  text-transform: none;
  padding: 2rem 0 1rem 1rem;
`;

const NoResultsTextWrap = styled(Text)`
  padding-left: 15px;
`;

const ResultBlock = styled(Block)`
  padding-top: 0;
  min-height: 190px;
`;

const ResultsLoading = styled.div`
  font-size: 3rem;
  padding: 65px 0;
  text-align: center;
  color: ${theme("Colors.PrimaryBlue")};
`;

const useStyles = makeStyles(() => ({
  paper: {
    padding: "0",
    marginTop: 20,
    overflow: "auto",
    maxHeight: 400,
    border: 0,
  },
  table: {
    width: "unset",
    tableLayout: "fixed",
  },
  popper: {
    top: "1px !important",
    width: "100%",
    zIndex: 30,
    borderBottom: "4px",
    boxShadow: `0 20px 10px -10px ${LightGray}`,
  },
}));

interface SearchResultsProps {
  hint: boolean;
  error: boolean;
  loading: boolean;
  open: boolean;
  results: SearchProperties[];
}

export const SearchResults = (props: SearchResultsProps) => {
  const classes = useStyles();
  const { hint, loading, open, error, results } = props;

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={document.getElementById("part-input")?.parentElement}
      placement={"bottom-start"}
      modifiers={{
        flip: {
          enabled: false,
        },
      }}
    >
      <Paper className={classes.paper} square={true} variant="outlined">
        <ResultBlock border={false}>
          {error && (
            <>
              <SearchResultHeading />
              <Error data={{}}>Failed to execute search</Error>
            </>
          )}

          {loading && (
            <ResultsLoading>
              <i className="fas fa-spin fa-spinner" title="spinner" />
            </ResultsLoading>
          )}

          {hint && !loading && !error && results.length < 1 && (
            <>
              <SearchResultHeading>
                You can search for&#8230;
              </SearchResultHeading>
              <Table className={classes.table}>
                <TableBody>
                  <SearchResultHint />
                </TableBody>
              </Table>
            </>
          )}
          {!hint && !loading && !error && results.length === 0 && (
            <>
              <SearchResultHeading>No results found.</SearchResultHeading>
              <NoResultsTextWrap>
                Please check the part number and try again.
              </NoResultsTextWrap>
            </>
          )}
          {!loading && !error && results.length > 0 && (
            <>
              <SearchResultHeading>Results</SearchResultHeading>
              <Table className={classes.table}>
                <TableBody>
                  {results.map((part) => (
                    <SearchResult
                      key={part.partInstanceId}
                      partNumber={part.partNumber}
                      partName={part.partName}
                      serialNumber={part.serialNumber}
                      partInstanceId={part.partInstanceId}
                      lotNumber={part.lotNumber}
                    />
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </ResultBlock>
      </Paper>
    </Popper>
  );
};
