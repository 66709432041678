import {
  Block,
  Column,
  ColumnWidth,
  Container,
  Row,
  ScrollNav,
  ScrollNavItem,
  ScrollNavManager,
  Segment,
  Sticky,
  Error,
} from "@blueorigin/blue-branding-kit";
import { LightHeading } from "@blueorigin/blue-branding-kit/components/text/light-heading";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { appConfig } from "../../../config";
import { EngineeringChange, PartInstance } from "../../../models/generated";
import { Routes } from "../../../routes";
import { ChangeImpact } from "./change-impact";
import { ChangeSummary } from "./change-summary";
import {
  setFilledAction,
  LayoutFilledActionPayload,
} from "../../../redux/layout/layout-actions";
import { setDisplayAction } from "../../../redux/display/display-actions";
import { DisplayState } from "../../../redux/display/display-state";
import { ActionCreator } from "typescript-fsa";
import { formatChangeClassification } from "./change-classification";
import { configureBreadcrumbs } from "../page-header-manager";
import { gql, QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { PageLoading } from "@blueorigin/blue-branding-kit/components/loading/page-loading";
import { BreadcrumbsPartInstanceFragment } from "../../../shared-queries";
import { DisplayComponent } from "../../../constants";

export const ChangePageAnchors = {
  Summary: "summary",
  Impact: "impact",
};

export const EngineeringChangeFragment = gql`
  fragment engineeringChange on Query {
    engineeringChange(reportId: $reportId) {
      reportId
      name
      changeClassification
      status
      approvalTimestamp
      summary {
        initialCondition
        shouldBeCondition
        changeRationale
      }
      impact {
        subsystem
        overallSystemImpact
      }
    }
  }
`;

export const ChangePageWithPartQuery = gql`
  ${EngineeringChangeFragment}
  ${BreadcrumbsPartInstanceFragment}
  query Q($reportId: String!, $partInstanceId: String!) {
    ...engineeringChange
    ...partInstance
  }
`;

export const ChangePageQuery = gql`
  ${EngineeringChangeFragment}
  query Q($reportId: String!) {
    ...engineeringChange
  }
`;

const ERROR_TITLE = "Engineering Change Not Found";

export interface ChangePageProps
  extends RouteComponentProps<{ reportId: string; partInstanceId?: string }> {
  setFilledAction: (payload: LayoutFilledActionPayload) => void;
  setDisplayAction: ActionCreator<DisplayState>;
}

export class ChangePageComponent extends React.Component<ChangePageProps> {
  public manager: ScrollNavManager;

  public constructor(props: ChangePageProps) {
    super(props);
    this.manager = new ScrollNavManager({ offset: -100 });
  }

  public componentDidMount() {
    this.configureSearchBar();
  }

  public configureSearchBar = () => {
    this.props.setDisplayAction({
      payload: DisplayComponent.searchBar,
    });
  };

  public configureHeader(
    change?: EngineeringChange,
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">
  ) {
    this.props.setFilledAction({
      title: change ? change.name : ERROR_TITLE,
      attributes: change
        ? [
            {
              label: "Type",
              value: formatChangeClassification(change.changeClassification),
            },
          ]
        : undefined,
      breadcrumbs: change
        ? configureBreadcrumbs(partInstance?.ancestry, false)
        : configureBreadcrumbs(),
    });
  }

  public render() {
    const { reportId, partInstanceId } = this.props.match.params;
    return (
      <Query
        query={partInstanceId ? ChangePageWithPartQuery : ChangePageQuery}
        variables={partInstanceId ? { reportId, partInstanceId } : { reportId }}
      >
        {this.renderQuery}
      </Query>
    );
  }

  public renderQuery = ({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<{
    engineeringChange: EngineeringChange;
    partInstance?: Pick<PartInstance, "ancestry" | "partInstanceId">;
  }>) => {
    if (loading) {
      return <PageLoading />;
    }

    if (error || !data?.engineeringChange) {
      this.configureHeader();
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load engineering change page
        </Error>
      );
    }

    const { engineeringChange: change, partInstance } = data;
    this.configureHeader(change, partInstance);

    const pageUrl = `${appConfig.appUri}${Routes.engineeringChange(
      change.reportId,
      partInstance?.partInstanceId
    )}`;
    const { manager } = this;
    const displayChangeImpact = Boolean(
      change.impact?.overallSystemImpact || change.impact?.subsystem
    );
    return (
      <Container>
        <Row>
          <Column width={ColumnWidth.fillWidth}>
            {change.summary && (
              <ChangeSummary
                manager={manager}
                pageUrl={pageUrl}
                summary={change.summary}
                approvalTimestamp={change.approvalTimestamp}
              />
            )}
            {change.impact && displayChangeImpact && (
              <ChangeImpact
                manager={manager}
                pageUrl={pageUrl}
                impact={change.impact}
                changeClassification={change.changeClassification}
              />
            )}
          </Column>
          <Column width="300px">
            <Sticky>
              <Block extraPadding border={false}>
                <ScrollNav manager={manager}>
                  <Segment>
                    <LightHeading>Navigation</LightHeading>
                  </Segment>
                  <Segment>
                    <ScrollNavItem
                      id={ChangePageAnchors.Summary}
                      manager={manager}
                    >
                      Summary
                    </ScrollNavItem>
                    {change.impact && displayChangeImpact && (
                      <ScrollNavItem
                        id={ChangePageAnchors.Impact}
                        manager={manager}
                      >
                        Impact
                      </ScrollNavItem>
                    )}
                  </Segment>
                </ScrollNav>
              </Block>
            </Sticky>
          </Column>
        </Row>
      </Container>
    );
  };
}

export const ChangePage = connect(null, { setFilledAction, setDisplayAction })(
  withRouter<any, any>(ChangePageComponent)
);
