import { Segment } from "@blueorigin/blue-branding-kit";
import React, { useRef } from "react";
import styled from "styled-components";
import { FileComponentProps } from "./file-page";

const Frame = styled.iframe`
  width: 100%;
  height: 75vh;
  min-height: 400px;
`;

export const FileEmbed = (props: FileComponentProps) => {
  const { fileName, url } = props.file;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <Segment>
      <Frame src={url} title={fileName} ref={iframeRef} frameBorder="0" />
    </Segment>
  );
};
