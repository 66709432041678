import { Segment, Text } from "@blueorigin/blue-branding-kit";
import * as React from "react";
import styled from "styled-components";

export enum KeyValueWeight {
  Light,
  Strong,
}

export interface KeyValueProps {
  label: React.ReactElement | string;
  value: React.ReactElement | string;
  weight: KeyValueWeight;
}

const Strongish = styled.strong`
  font-weight: 600;
`;

export class KeyValue extends React.Component<KeyValueProps> {
  public static defaultProps = {
    weight: KeyValueWeight.Strong,
  };

  public render() {
    const { label, value, weight } = this.props;
    if (weight === KeyValueWeight.Strong) {
      return (
        <React.Fragment>
          <Segment>
            <Text>
              <strong>{label}</strong>
            </Text>
          </Segment>
          <Segment>
            <Text>{value}</Text>
          </Segment>
        </React.Fragment>
      );
    } else if (weight === KeyValueWeight.Light) {
      return (
        <Segment>
          <Text>
            <Strongish>
              {label}
              {": "}
            </Strongish>
            {value}
          </Text>
        </Segment>
      );
    }
    return null;
  }
}
