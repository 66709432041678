import * as React from "react";
import { useQuery } from "@apollo/client";
import { makeConditionalLookup } from "../shared-queries";

export const useLookup = <
  QueryField extends string,
  InstanceField extends string,
  Instances extends Record<InstanceField, string | null>
>(
  queryName: string,
  queryField: QueryField,
  instanceField: InstanceField,
  instances: Instances[] | null
) => {
  const [linkableIds, setLinkableIds] = React.useState<string[]>([]);

  const ids = instances
    ?.filter((instance) => instance[instanceField] != null)
    ?.map((instance) => instance[instanceField]!);

  const PartInstanceQuery = makeConditionalLookup(queryName, queryField, ids);

  const { data } = useQuery<Record<string, Record<QueryField, string | null>>>(
    PartInstanceQuery,
    {
      skip: !Boolean(ids && ids.length > 0),
      errorPolicy: "ignore",
    }
  );

  React.useEffect(() => {
    if (data) {
      setLinkableIds(
        Object.values(data)
          .filter((value) => value != null)
          .map((value) => value[queryField]!)
      );
    }
  }, [data, queryField]);

  return { linkableIds };
};
