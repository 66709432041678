import {
  IconButton,
  theme,
  FauxLink,
  Text,
  Notice,
  Segment,
} from "@blueorigin/blue-branding-kit";
import { FileType } from "@blueorigin/blue-branding-kit/utils/files";
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Routes } from "../../../routes";
import { Mood } from "@blueorigin/blue-branding-kit/models/mood";
import { FileComponentProps } from "./file-page";

const InstructionsTitle = styled.span`
  margin-right: ${theme("Dimensions.Space")};
`;

const instructions: Record<FileType, string> = {
  [FileType.MICROSOFT_WORD]: "This file can be opened using Microsoft Word.",
  [FileType.MICROSOFT_EXCEL]: "This file can be opened using Microsoft Excel.",
  [FileType.MICROSOFT_POWERPOINT]:
    "This file can be opened using Microsoft PowerPoint.",
  [FileType.IMAGE_PNG]: "This file can be opened with an image viewer.",
  [FileType.IMAGE_JPG]: "This file can be opened with an image viewer.",
  [FileType.IMAGE_GIF]: "This file can be opened with an image viewer.",
  [FileType.ADOBE_PDF]: "This file can be opened using Adobe Acrobat.",
  [FileType.OTHER]: "",
};

export const FileToolbar = (props: FileComponentProps) => {
  const { fileType, file } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const dismissButtonRef = useRef<HTMLButtonElement>(null);
  const triggerButtonRef = useRef<HTMLButtonElement>(null);

  const handleExpand = () => {
    setIsExpanded(true);
    window.requestAnimationFrame(() => dismissButtonRef.current?.focus());
  };

  const handleCollapse = () => {
    setIsExpanded(false);
    window.requestAnimationFrame(() => triggerButtonRef.current?.focus());
  };

  if (isExpanded) {
    return (
      <Segment>
        <Notice
          mood={Mood.COOL}
          title={
            <>
              <InstructionsTitle>Instructions</InstructionsTitle>
              <FauxLink
                innerRef={dismissButtonRef}
                onClick={handleCollapse}
                aria-expanded
                aria-controls="file-instructions-content"
              >
                Dismiss
              </FauxLink>
            </>
          }
        >
          <Text id="file-instructions-content">
            {instructions[fileType]}
            {instructions[fileType] && " "}
            For additional help, please{" "}
            <a href={Routes.contact} target="_blank" rel="noopener noreferrer">
              contact us
            </a>
            .
          </Text>
        </Notice>
      </Segment>
    );
  }

  return (
    <Segment>
      {fileType === FileType.ADOBE_PDF && (
        <IconButton
          href={file.url}
          download
          target="_blank"
          rel="noopener"
          icon="fa-cloud-download-alt"
        >
          Download
        </IconButton>
      )}
      <IconButton
        ref={triggerButtonRef}
        onButtonClick={handleExpand}
        aria-expanded={false}
        aria-controls="file-instructions-content"
        iconSet="far"
        icon="fa-file-alt"
      >
        Instructions
      </IconButton>
    </Segment>
  );
};
