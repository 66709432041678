import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import { withRouter } from "react-router-dom";
import { BlueFooter } from "@blueorigin/public-site-components/components/blue-footer/blue-footer";
import { Routes } from "../../routes";
import { HydrationStatus } from "../../redux/layout/layout-state";

interface FooterComponentProps {
  isSuccessDeposit?: boolean;
  location?: any;
  hydration?: HydrationStatus;
}

export class FooterComponent extends React.Component<FooterComponentProps> {
  public render() {
    const { hydration } = this.props;
    const displayFooter =
      hydration === HydrationStatus.Filled ||
      hydration === HydrationStatus.Loading;
    return (
      displayFooter && (
        <BlueFooter
          onContactClick={() => window.location.assign(Routes.contact)}
        />
      )
    );
  }
}

export const mapStateToProps = (
  state: AppState,
  props: FooterComponentProps
) => {
  return {
    ...props,
    hydration: state.layout.hydration,
  };
};

export const Footer = connect(mapStateToProps)(
  withRouter<any, any>(FooterComponent as any)
);
